import { getUserRole } from "contexts/AuthContext";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { ModalContent } from "../../pageComponents/cardPageComponents/components/CardModal";
import CardTransaction from "../../pageComponents/cardPageComponents/components/CardModal/screens/CardTransaction";
import CardTransactionDetail from "../../pageComponents/cardPageComponents/components/CardModal/screens/CardTransactionDetail";
import MerchantLinked from "../../pageComponents/cardPageComponents/components/CardModal/screens/MerchantLinked";
import { apiBusiness, fetch } from "../../tools/api";
import { eventsTracker } from "../../universalFunctions/events";

export const CardDetailContext = createContext();

const useHooks = (array) => {
  const [hooks, setHooks] = useState({
    rightModalArray: array,
    rightModalWidth: [],
    currentScreen: false,
    cardDetailLatest: {},
    unmaskedDataDetail: {},
    selectedTransaction: null,
    updatedTransaction: null,
    hideClose: false,
    isHideModal: false,
  });

  const pendingUpdates = useRef({});
  const timeoutRef = useRef(null);

  const setHooksBatch = (key, funcOrValue) => {
    // Collect the updates
    pendingUpdates.current[key] =
      typeof funcOrValue === "function" ? funcOrValue(hooks[key]) : funcOrValue;

    // Clear the timeout if it's already running
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Delay execution to batch updates
    timeoutRef.current = setTimeout(() => {
      setHooks((prev) => ({
        ...prev,
        ...pendingUpdates.current,
      }));
      // Clear pending updates after they're applied
      pendingUpdates.current = {};
    }, 400); // Delay set to 400ms
  };

  // Individual setters
  const setRightModalArray = (funcOrValue) =>
    setHooksBatch("rightModalArray", funcOrValue);
  const setRightModalWidth = (funcOrValue) =>
    setHooksBatch("rightModalWidth", funcOrValue);
  const setCurrentScreen = (funcOrValue) =>
    setHooksBatch("currentScreen", funcOrValue);
  const setCardDetailLatest = (funcOrValue) =>
    setHooksBatch("cardDetailLatest", funcOrValue);
  const setUnmaskedDataDetail = (funcOrValue) =>
    setHooksBatch("unmaskedDataDetail", funcOrValue);
  const setHideClose = (funcOrValue) => setHooksBatch("hideClose", funcOrValue);
  const setUpdatedTransaction = (funcOrValue) =>
    setHooksBatch("updatedTransaction", funcOrValue);
  const setIsHideModal = (funcOrValue) =>
    setHooksBatch("isHideModal", funcOrValue);

  const setSelectedTransaction = (funcOrValue) => {
    setHooksBatch("selectedTransaction", funcOrValue);
    if (!funcOrValue) return;
    eventsTracker("card_transaction_open_detail", {
      card_transaction_id: hooks.selectedTransaction?.id,
    });
    setRightModalArray([
      <ModalContent />,
      <CardTransaction />,
      <CardTransactionDetail
        transactionDetail={funcOrValue}
        setUpdatedTransaction={setUpdatedTransaction}
      />,
    ]);

    setRightModalWidth([420, 510, 420]);
  };

  return {
    ...hooks,
    setRightModalArray,
    setRightModalWidth,
    setCurrentScreen,
    setCardDetailLatest,
    setUnmaskedDataDetail,
    setHideClose,
    setUpdatedTransaction,
    setIsHideModal,
    setSelectedTransaction,
  };
};

export const CardDetailContextProvider = ({
  children,
  setCards,
  cardDetail,
  toggle,
  isArchiveScreen,
  toggleConfirmDelete,
  isTask,
  setSelectedItem,
  isReveal,
  isAlert,
  isNotRenavigate,
}) => {
  const array = [<ModalContent />];

  const { query, replace } = useRouter();
  const { cardTransactionId, isTopUp, originator_id } = query;

  const queryCardTransactionId = cardTransactionId || originator_id;

  const {
    cardDetailLatest,
    currentScreen,
    hideClose,
    isHideModal,
    rightModalArray,
    rightModalWidth,
    selectedTransaction,
    unmaskedDataDetail,
    updatedTransaction,
    setCardDetailLatest,
    setCurrentScreen,
    setHideClose,
    setIsHideModal,
    setRightModalArray,
    setRightModalWidth,
    setSelectedTransaction,
    setUnmaskedDataDetail,
    setUpdatedTransaction,
  } = useHooks(array);

  const rightModalToggle = () => {
    toggle();
    if (isTask || isAlert) return;

    setCurrentScreen("index");
    setUnmaskedDataDetail({});
    setSelectedItem && setSelectedItem(null);
    setCardDetailLatest({});
    let newQuery = {
      ...query,
      cardTransactionId: undefined,
      isCardRequest: undefined,
      id: undefined,
      runQueryTransaction: undefined,
      sort_by: undefined,
      merchant_name: undefined,
      transaction_status: undefined,
      isTopUp: undefined,
    };
    newQuery = Object.keys(newQuery).reduce((acc, key) => {
      if (newQuery[key] !== undefined) {
        acc[key] = newQuery[key];
      }
      return acc;
    }, {});

    if (isNotRenavigate) return;

    replace({
      pathname: "/cards",
      query: newQuery,
    });
  };

  const afterSuccessCardDetail = (res) => {
    const item = res?.data;
    const { id, is_archived: isArchived } = item;
    setCardDetailLatest(item);

    if (isArchived && !isArchiveScreen) {
      rightModalToggle();
      return setCards((prev) => {
        const index = prev.findIndex((item) => item?.id === id);
        const newArray = [...prev];
        newArray.splice(index, 1);
        return newArray;
      });
    }

    setCards((prev) => {
      const index = prev.findIndex((item) => item?.id === id);
      const newArray = [...prev];
      newArray[index] = item;
      return newArray;
    });
  };

  const { refetch, loading: loadingCardDetail } = fetch({
    url: `/cards/${
      cardDetailLatest?.id ||
      cardDetail?.originator?.originator_id ||
      cardDetail?.originator_id ||
      cardDetail?.id
    }`,
    woInit: true,
    afterSuccess: afterSuccessCardDetail,
  });

  const refetchBalance = async () => {
    const { data } = await apiBusiness.get(
      `/cards/${
        cardDetailLatest?.id ||
        cardDetail?.originator?.originator_id ||
        cardDetail?.id
      }`
    );
    afterSuccessCardDetail(data);
  };

  const [mainBalance, setMainBalance] = useState(0);

  const refetchMainBalance = async () => {
    const { data } = await apiBusiness.get("/my_company_balance");
    const balance = data?.data?.disbursement_balance;
    if (!balance) return 0;
    return setMainBalance(balance[0]?.balance);
  };

  const { isEmployee } = getUserRole();

  useEffect(() => {
    if (isEmployee) return;
    refetchMainBalance();
  }, []);

  const toggleAdjacentModal = (name, component) => {
    closeAdjacentModal();
    let isOpenAdjacentModal;
    setRightModalArray((prev) => {
      isOpenAdjacentModal = Boolean(
        prev.find((item) => item?.type?.name === name)
      );
      if (isOpenAdjacentModal) return [prev[0]];

      const newArray = [...prev];
      newArray.push(component);
      return newArray;
    });
    if (isOpenAdjacentModal) return setRightModalWidth([420]);

    setRightModalWidth((prev) => {
      const newArray = [...prev];
      newArray.push(510);
      return newArray;
    });
  };

  const openTransactionList = () => {
    eventsTracker("card_open_transaction_list", {
      card_id: cardDetailLatest?.id,
    });
    toggleAdjacentModal("CardTransaction", <CardTransaction />);
    setSelectedTransaction(null);
  };

  const closeTransactionList = () => {
    setRightModalArray(array);
    setRightModalWidth([420]);
  };

  const openMerchantLinked = () => {
    eventsTracker("card_open_merchant_linked", {
      card_id: cardDetailLatest?.id,
    });
    toggleAdjacentModal("MerchantLinked", <MerchantLinked />);
  };

  const closeAdjacentModal = () => {
    setRightModalArray(array);
    setRightModalWidth([420]);
  };

  const resetModal = (screen) => {
    if (currentScreen == screen) return;

    setCurrentScreen(screen);
    closeAdjacentModal();
  };

  const openTransactionDetail = () => {
    eventsTracker("card_transaction_open_detail", {
      card_transaction_id: selectedTransaction?.id,
    });
    setRightModalArray([
      <ModalContent />,
      <CardTransaction />,
      <CardTransactionDetail
        transactionDetail={selectedTransaction}
        setUpdatedTransaction={setUpdatedTransaction}
      />,
    ]);

    setRightModalWidth([420, 510, 420]);
  };

  useEffect(() => {
    if (cardDetail) refetch();
  }, [!!cardDetail]);

  useEffect(() => {
    setRightModalArray(array);
    setRightModalWidth([420]);
  }, [cardDetailLatest, unmaskedDataDetail]);

  useEffect(() => {
    const isCardDetailExist = !isEmpty(cardDetailLatest);
    if (queryCardTransactionId && isCardDetailExist)
      setSelectedTransaction({ id: queryCardTransactionId });

    if (isTopUp && isCardDetailExist) {
      setTimeout(() => {
        setCurrentScreen("prepaidTopUpMethod");
      }, 500);
    }
  }, [cardDetailLatest, queryCardTransactionId, isTopUp]);

  useEffect(() => {
    resetModal(currentScreen);
    closeAdjacentModal();
  }, [currentScreen]);

  useEffect(() => {
    if (isReveal) setIsHideModal(true);
  }, [isReveal]);

  const value = {
    rightModalArray,
    rightModalWidth,
    rightModalToggle,
    cardDetail: cardDetailLatest,
    unmaskedDataDetail,
    openTransactionList,
    closeTransactionList,
    refetchCardDetail: refetch,
    setHideClose,
    refetchBalance,
    hideClose,
    updatedTransaction,
    setUpdatedTransaction,
    selectedTransaction,
    openTransactionDetail,
    setSelectedTransaction,
    loadingCardDetail,
    currentScreen,
    setCurrentScreen,
    mainBalance,
    refetchMainBalance,
    toggleConfirmDelete,
    openMerchantLinked,
    isTask,
    closeAdjacentModal,
    setUnmaskedDataDetail,
    isReveal,
    isHideModal,
    setIsHideModal,
    isNotRenavigate,
  };

  return (
    <CardDetailContext.Provider value={value}>
      {children}
    </CardDetailContext.Provider>
  );
};

export const useCardDetailContext = () => useContext(CardDetailContext);
