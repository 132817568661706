import { useEffect, useState } from "react";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { useLayout } from "../../../contexts/Layout/parent";
import { Avatar } from "../../Avatar";
import { Counter } from "../../Counter";
import { GothamMedium } from "../../Text";
import { useDebounce } from "../../tools";
import { TableJackSimple } from "../parent";
import { useModalHook } from "../../Modals";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

export const DropdownLeftGenerator = ({
  fill,
  backgroundColor = jackColors.black34,
  title = "",
  number = 0,
  // customComponents,
}) => (
  <>
    {/* <div className="d-flex align-items-center justify-content-between w-100"> */}
    <div className="d-flex align-items-center">
      <Avatar
        style={{ backgroundColor }}
        size="smallest"
        mainIcon={
          <JackIcons
            name="alert-triangle-outline"
            style={{ height: 12, width: 12 }}
            fill={fill}
          />
        }
      />
      <GothamMedium
        className="font12"
        style={{ marginLeft: 18, marginRight: 8, color: jackColors.black34 }}
      >
        {title}
      </GothamMedium>
      <Counter number={number} isOrange />
    </div>
    {/* {customComponents} */}
    {/* </div> */}
  </>
);

export const DropdownTableSimple = ({
  isAllowOverflow = false,
  headerTable = [],
  bodyComponent = null,
  leftComponent = null,
  rightComponent = null,
  tbodyRef = () => {},
  dropdownProps: {
    isOpen: isOpenProps,
    setIsOpen,
    name,
    hasMore,
    hasMoreForced,
    additionalOffset = 80,
  },
  headerStyles = {},
  bodyTableArr = [], // use this to make table more responsive to deleted changes
  id = "dropdowntable",
}) => {
  const isOpen = isOpenProps == name;
  const hide = isOpenProps && !isOpen;
  const showMore = (hasMore && !isOpen) || hasMoreForced;
  const { isOpen: isRunReset, toggle: toggleReset } = useModalHook();

  const { clientHeight, scrollTop } = useLayout();

  const screenHeight = clientHeight;

  const debouncedHide = useDebounce(hide, 10);
  const [ref, setRef] = useState(null);

  useEffect(() => {
    setTimeout(() => setRef(null), 400);
  }, []);

  useEffect(() => {
    if (!ref) return;

    setTimeout(() => {
      setRef((prevRef) => {
        const isReset = prevRef && typeof prevRef === "object";
        if (isReset) return null;
        return prevRef; // Return the previous ref value
      });
    }, 400);
  }, [bodyTableArr.length, isRunReset]);

  const hasLeftRightComponent =
    !isEmpty(leftComponent) || !isEmpty(rightComponent);
  const leftRightHeight = hasLeftRightComponent ? 40 : 0;
  const defaultHeight = (ref?.clientHeight || 0) + leftRightHeight;
  const offsetTop = (ref?.offsetTop || 0) + additionalOffset;

  const scrolledScreenHeight = screenHeight + scrollTop;
  const heightFromTop = defaultHeight + offsetTop;

  const headerHeight = 40;

  const componentHeightDecider = () => {
    if (hide) return 0;
    if (showMore) {
      if (!debouncedHide && hide) return "auto";

      return defaultHeight + headerHeight;
    }
    return defaultHeight - 10;
  };

  const componentHeight = componentHeightDecider();

  const [floatingRef, setFloatingRef] = useState(null);
  const left = `calc(50% - ${(floatingRef?.clientWidth || 0) / 2}px)`;
  const showFloatingButton =
    scrolledScreenHeight < heightFromTop &&
    scrolledScreenHeight > offsetTop + 100;

  const { t } = useTranslation("task");

  return (
    <div
      id={id}
      style={{
        borderRadius: 8,
        border: `1px solid ${jackColors.greyE6}`,
        paddingBottom: -10,
        height: componentHeight,
        overflow: isAllowOverflow ? "visible" : "hidden",
        transition: "all 0.4s linear",
        visibility: hide ? "hidden" : "visible",
        display: "flex",
        flexDirection: "column",
      }}
      className="fade-in"
    >
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          height: headerHeight,
          paddingLeft: 18,
          borderBottom: "1px solid #E6E6E8",
          backgroundColor: "white",
          ...headerStyles,
        }}
      >
        {leftComponent}
        {rightComponent}
      </div>
      <div ref={(e) => setRef(e)}>
        <TableJackSimple
          tbodyRef={tbodyRef}
          headerTable={headerTable}
          bodyComponent={bodyComponent}
          isAllowOverflow={isAllowOverflow}
          style={{ borderRadius: 0, borderWidth: 0 }}
          thStyle={{
            fontFamily: "GothamBook",
            fontSize: 12,
            borderBottom: "none",
          }}
          trStyle={{ height: 40 }}
          tableStyle={{ borderRadius: 0, borderWidth: 0 }}
        />
      </div>
      {showMore && (
        <div
          className="d-flex justify-content-center align-items-center hover"
          style={{
            height: 40,
            gap: 8,
            marginTop: -4,
            backgroundColor: "white",
            position: "relative",
            zIndex: 0,
          }}
          onClick={() => {
            toggleReset();
            setIsOpen(name);
          }}
        >
          <GothamMedium style={{ color: jackColors.black34 }}>
            {t("See more")}
          </GothamMedium>
          <JackIcons
            name="chevron-down"
            fill={jackColors.black34}
            style={{ heigh: 20, width: 20 }}
          />
        </div>
      )}
      {isOpen && showFloatingButton && (
        <div
          style={{
            position: "fixed",
            bottom: 120,
            zIndex: 1,
            left,
            backgroundColor: "white",
            boxShadow: "0px 16px 24px 0px #16223314",
            padding: 6,
            border: `1px solid ${jackColors.greyE6}`,
            borderRadius: 4,
            height: 32,
            gap: 8,
          }}
          className="d-flex hover"
          ref={(e) => setFloatingRef(e)}
          onClick={() => {
            toggleReset();
            setIsOpen(null);
          }}
        >
          <GothamMedium>{t("See Less")}</GothamMedium>
          <JackIcons
            name="chevron-up"
            className="hover"
            fill={jackColors.black34}
            style={{ heigh: 20, width: 20, marginTop: 4 }}
          />
        </div>
      )}
    </div>
  );
};
