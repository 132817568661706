import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { colors, jackColors } from "../../../../../assets/colors";
import { GothamMedium } from "../../../../../components/Text";
import { MissingDetailTag } from "../../../../categoryComponents/general/components";
import { Fragment } from "react";
import { useCategoryFlag } from "../../../../categoryComponents/general/hooks";
import { paymentMethodDecider, transactionTypeDecider } from "../../../tools";
import { useTranslation } from "react-i18next";

const CardHeader = ({
  cardDetail,
  isTask,
  transactionDetail,
  isTransactionSuccess,
  icon = "corporate-card",
}) => {
  const { t } = useTranslation("credit/credit");
  const {
    current_workflow_process: currentWorkflowProcess,
    name,
    nickname,
  } = cardDetail || {};
  const {
    has_attachment,
    notes,
    category_id,
    payment_method: paymentMethod,
    transaction_type: transactionType,
  } = transactionDetail ?? {};

  const { shallCheckMissingCategory } = useCategoryFlag();

  const isMissingCategory = !category_id && shallCheckMissingCategory;

  const nameOrNickname = nickname || name;
  const isDetailModal = !nameOrNickname;

  const { isMainBalance, isMoveBalance, isBackOffice } =
    paymentMethodDecider(paymentMethod);
  const { isCreditTopUp } = transactionTypeDecider(transactionType);

  const isShowReceiptNotes =
    !isBackOffice && isTransactionSuccess && (isMissingReceipt || !notes);
  const isWoReceipt = isMainBalance || isMoveBalance || isCreditTopUp;
  const isMissingReceipt = !isWoReceipt && !has_attachment;

  const isMissingDetails = isShowReceiptNotes || isMissingCategory;

  const containerStyle = {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  };

  const iconContainerStyle = {
    display: "grid",
    placeItems: "center",
    borderRadius: "50%",
    width: "32px",
    height: "32px",
    backgroundColor: colors.neutral900,
  };

  const iconStyle = { width: "16px" };

  const textStyle = {
    fontSize: "16px",
    lineHeight: "24px",
    color: colors.neutral900,
  };

  if (isTask) {
    const isRequestLimit = currentWorkflowProcess === "request_adjust_limit";
    const titleHeader = isRequestLimit
      ? t("Card Limit Adjustment Request")
      : t("Card Request");
    return <GothamMedium style={textStyle}>{titleHeader}</GothamMedium>;
  }

  return (
    <div style={containerStyle}>
      <div style={iconContainerStyle}>
        <JackIcons name={icon} fill={colors.neutral100} style={iconStyle} />
      </div>
      {isDetailModal ? (
        <Fragment>{isMissingDetails && <MissingDetailTag />}</Fragment>
      ) : (
        <GothamMedium style={textStyle}>{nameOrNickname}</GothamMedium>
      )}
    </div>
  );
};

export default CardHeader;
