import { useEffect, useState } from "react";
import {
  containsOnlyDigits,
  formatCurrencyNoDecimal,
  unformatCurrencyDecimal,
} from "../../../components/tools";
import { useBooleanTable } from "./table/logic";
import { Trans } from "react-i18next";

export const minimumPayrollAmount = 10000;
export const cleanNumber = (value) => Number(unformatCurrencyDecimal(value));

export const useCheckerErrorAddRow = ({ data }) => {
  let counter = 0;
  const newData = data?.map((item, index) => {
    const newItem = item?.map((sub, i) => {
      const { isRequired, isSalary } = useBooleanTable({ name: sub?.name });
      if (isSalary && cleanNumber(sub?.value) < minimumPayrollAmount)
        return counter++;
      if (isRequired && sub?.value.length === 0) return counter++;
      return;
    });
    return newItem;
  });

  if (counter > 0) return true;
  return false;
};

export const ErrorMessageGenerator = ({ name, value, t = (item) => item }) => {
  const { isBank, isSalary } = useBooleanTable({ name });

  const isBelowMinimumAmount =
    isSalary && cleanNumber(value) < minimumPayrollAmount;

  const msgDecider = () => {
    if (isSalary && cleanNumber(value) < minimumPayrollAmount) {
      return (
        <Trans
          i18nKey={t("dynamic.dynamic_text_8", {
            amount: formatCurrencyNoDecimal(minimumPayrollAmount),
          })}
        />
      );

      // return `Minimum amount: IDR ${formatCurrencyNoDecimal(
      //   minimumPayrollAmount
      // )}.`;
    }

    if (isBank) return t(`Choose a Bank`);
    return t(`Please fill out this field`);
  };

  const msg = msgDecider();
  return { msg, isBelowMinimumAmount };
};

export const PayrollFuncMix = (data) => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender)
      return setTimeout(() => {
        setIsFirstRender(false);
      }, 500);
  }, [isFirstRender]);

  const isKick = data.length === 0 && !isFirstRender;

  return { isKick };
};

export const isErrorOnDataTable = ({ array }) => {
  let counter = 0;
  const newArray = array?.map((item, index) => {
    if (item?.invalid || item?.invalidFormat) return counter++;
  });

  return counter > 0 ? true : false;
};

export const isDuplicateOnDataTable = ({ array }) => {
  let counter = 0;
  array.forEach((item) => {
    if (item?.duplicate || item?.invalidFormat) return counter++;
  });
  return counter > 0 ? true : false;
};

export const isThereIncompleteData = ({ data = [] }) => {
  let counter = 0;
  const newData = data?.map((array, index) => {
    const newArray = array?.map((item, index) => {
      if (item?.invalid || item?.invalidFormat) return counter++;
    });
  });

  return counter > 0 ? true : false;
};

export const filterDataByIncomplete = ({ data }) => {
  const dataIncomplete = [];

  for (let i = 0; i < data.length; i++) {
    let foundInvalid = false;
    for (let j = 0; j < data[i]?.length; j++) {
      if (data[i][j].invalid || data[i][j].invalidFormat) {
        foundInvalid = true;
        break;
      }
    }
    if (foundInvalid) {
      dataIncomplete.push(data[i]);
    } else {
      dataIncomplete.push(undefined);
    }
  }

  const dataOnlyValid = dataIncomplete?.filter((item) => item);
  const dataIncompleteLength = dataIncomplete.filter((item) => item).length;
  const isEmptyIncompleteData = dataOnlyValid.length === 0;
  return { dataIncomplete, dataIncompleteLength, isEmptyIncompleteData };
};

export const filterDataByDuplicate = ({ data }) => {
  const dataDuplicate = [];

  for (let i = 0; i < data.length; i++) {
    let foundDuplicate = false;
    for (let j = 0; j < data[i]?.length; j++) {
      if (data[i][j].duplicate) {
        foundDuplicate = true;
        break;
      }
    }
    if (foundDuplicate) {
      dataDuplicate.push(data[i]);
    } else {
      dataDuplicate.push(undefined);
    }
  }

  const dataOnlyValid = dataDuplicate?.filter((item) => item);
  const dataDuplicateLength = dataDuplicate.filter((item) => item).length;
  const isEmptyDuplicateData = dataOnlyValid.length === 0;
  return { dataDuplicate, dataDuplicateLength, isEmptyDuplicateData };
};

export const checkerEmptyWhenUploadPayroll = ({ data }) => {
  const newData = data?.map((item, index) => {
    const newItem = item?.map((sub, i) => {
      const { isRequired, isName } = useBooleanTable({ name: sub?.name });

      // not able to enter only numbers
      const invalidFormat = isName && containsOnlyDigits(sub?.value);

      // required fields cannot empty
      const invalid = isRequired && !Boolean(sub?.value) ? true : false;

      return {
        ...sub,
        invalid,
        invalidFormat,
      };
    });
    return newItem;
  });
  return newData;
};

export const removeAllEmptyFieldPayroll = ({ data: datas }) => {
  if (datas.length === 1) return datas;

  let tempArray = [];

  for (let data of datas) {
    for (let item of data) {
      if (!!item.value) {
        tempArray.push(data);
        break;
      }
    }
  }
  return tempArray;
};

export function checkerValidationCustomPayroll({ data, type }) {
  let isNotPassCheckerTemplate = false;
  if (type == "talenta") {
    if (data.length < 1) isNotPassCheckerTemplate = true;
  }
  return { isNotPassCheckerTemplate };
}
