import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../../components/Loading";
import { useGetAuth } from "../../../contexts/AuthContext";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { isEmailChecker } from "../../localTransferEnhancement/create/table/inputs/email/chip";
import { CreateLocalTransfer } from "../../localTransferEnhancement/create/table/parent";
import { localTransferStates } from "../../payrollCreateComponents/loop/parent";
import { LocalTransferConfirmation } from "../confirmation/parent";
import { LocalTransferSuccessScreen } from "../success/parent";
import { ValidatingLocalTransfer } from "../validating/parent";
import { DataValidationPage } from "../validation/parent";
import { fetchGetAll } from "./fetch";
import {
  localTransferDefaultValuesFormatter,
  localTransferFormatter,
} from "./formatter";
import { defaultValuesFormatter, useLocalTransferLogics } from "./logics";
import { isEmpty } from "lodash";
import {
  ancestoryLinesFormatter,
  longestAncestoryLineDecider,
  retriableOrResubmittableTrxsDecider,
} from "../../../modals/smartActivityModal/retry-resubmit/helpers";
import { booleanState } from "../../../components/Status";
import { VALID_ERROR_CODES } from "../../../modals/smartActivityModal/retry-resubmit/constants";

export const getEmails = (value) => {
  if (!value) return value;
  const valueString = value + "";
  const emails = valueString
    .split(",")
    .map((string) => string.replace(" ", ""));
  const correctEmailsArr = emails
    .filter((email) => isEmailChecker(email))
    .slice(0, 6);
  const correctEmails = correctEmailsArr.join(",");
  return correctEmails;
};

export const LocalTransferFormLoop = ({
  defaultValues: valuesFromExcel,
  firstValues,
  isNotFromExcel,
}) => {
  const [categoryId, setCategoryId] = useState(null);

  const { loadingTransferBanks } = useConstants();
  const { user } = useGetAuth();
  const { buttonBooleans, partner } = user || {};
  const { id: partner_id } = partner || {};
  const { canViewLocalTransfer } = buttonBooleans || {};

  const { query, push } = useRouter();
  const { id, state: stateQuery } = query;
  const { isConfirmation, isSuccess, isValidating, isValidation } =
    localTransferStates(stateQuery);

  const { categories } = useConstants();

  const isEdit = id;

  useEffect(() => {
    if (canViewLocalTransfer) return;
    push("/home");
  }, [canViewLocalTransfer]);

  // fetch data

  const {
    data,
    loading: loadingFetch,
    refetch,
  } = fetchGetAll({
    url: `/local_transaction_batches/${id}/show_transactions`,
    key: "data",
    formatter: localTransferDefaultValuesFormatter,
  });

  useEffect(() => {
    if (!isEdit) return;
    refetch();
  }, [stateQuery]);

  // fetch data

  const {
    batch,
    defaultValuesTable,
    defaultValues,
    data: localTransactions,
  } = data || {};

  const { state, name } = batch || {};

  const category_id = firstValues
    ? firstValues?.category_id
    : !isEmpty(data)
    ? data.data[0]?.category_id
    : null;

  useEffect(() => {
    if (category_id) setCategoryId(category_id);
  }, [category_id]);

  // redirection
  useEffect(() => {
    if (!state) return;
    if (isValidating) return;
    if (isConfirmation) return;
    const isValidateRecipient = state == "validate_recipient";
    if (isValidateRecipient) push({ query: { ...query, state: "validating" } });
  }, [state]);
  // redirection

  const resubmittedTrxs =
    retriableOrResubmittableTrxsDecider(localTransactions);
  const resubmittedTrxIds = resubmittedTrxs?.map(({ id }) => id);

  const isResubmit = query.action === "resubmit";

  const defaultValuesTableToUse = isResubmit
    ? defaultValuesTable?.filter(({ id }) => resubmittedTrxIds?.includes(id))
    : defaultValuesTable;

  const valuesForTable = id ? defaultValuesTableToUse : valuesFromExcel;
  const titleForTable = id ? name : firstValues?.batch_name;

  useEffect(() => {
    if (id) return;
    if (firstValues) return;
    // push({});
  }, []);

  const { create, loading, update } = useLocalTransferLogics();

  const handleSubmit = (val) => {
    const { type } = val || {};
    const isDraft = type == "draft";

    const values = localTransferFormatter({
      ...val,
      partner_id,
      defaultValues,
      isEdit: isEdit && !isResubmit,
      category_id: categoryId,
      // ancestor_id: isResubmit && batch?.id,
    });
    const obj = { values, isValidate: !isDraft };
    if (isResubmit) return create(obj);
    if (isEdit) return update(obj);
    create(obj);
  };

  if (loadingTransferBanks) return <LoadingSpinner />;
  if (loadingFetch && id) return <LoadingSpinner />;

  if (isSuccess) return <LocalTransferSuccessScreen />;
  if (isConfirmation) return <LocalTransferConfirmation {...data} />;
  if (isValidation)
    return (
      <DataValidationPage
        {...data}
        batchId={isResubmit ? data?.batch?.id : null}
      />
    );
  if (isValidating) return <ValidatingLocalTransfer />;

  return (
    <CreateLocalTransfer
      category={categories.find(({ id }) => id === categoryId)}
      firstValues={firstValues}
      defaultValues={defaultValuesFormatter(valuesForTable)}
      titleBatch={titleForTable}
      isNotFromExcel={isNotFromExcel}
      onSubmit={handleSubmit}
      onChangeCategory={(category) => setCategoryId(category.id)}
      loading={loading}
    />
  );
};
