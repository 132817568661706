import React, { useEffect, useState } from "react";
import { useHeader } from "../../contexts/Layout/parent";
import { GothamMedium } from "../../components/Text";
import { jackColors } from "../../assets/colors";
import TabMenu from "../../components/TabMenu/parent";
import { useFetchTotalRow } from "./tools/useFetchRow";
import { DraftTabPage } from "./DraftTabPage/parent";
import { useGetAuth } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";

export const DraftContext = React.createContext({
  toBeDeleted: [],
  setToBeDeleted: () => {},
});

export const DraftComponent = () => {
  const { setHeader } = useHeader();
  const { t } = useTranslation("draft/draft");

  const [toBeDeleted, setToBeDeleted] = useState([]);

  const { user } = useGetAuth();
  const { id: user_id } = user || {};

  // const urlIndex = {
  //   local_transfer: `/local_transaction_batches?page=1&per_page=1&q[state_matches_any][]=draft&q[transaction_type_not_eq]=payroll&q[state_matches_any][]=validate_recipient&q[user_id_eq]=${user_id}`,
  //   reimbursement: `/reimbursements?page=1&per_page=1&q[user_id_eq]=${user_id}`,
  //   bill_payment : `/invoice_transactions/filtered/drafted?page=1&per_page=1&q[user_id_eq]=${user_id}`,
  //   payroll: `/payrolls?per_page=1&q[state_matches_any][]=created&q[batch_user_id_eq]=${user_id}`
  // }

  // const { totalRow: localTransferTotalRow, refetch: refetchLocal } = useFetchTotalRow(urlIndex.local_transfer)
  // const { totalRow: reimbursementTotalRow, refetch: refetchReimbursement } = useFetchTotalRow(urlIndex.reimbursement)
  // const { totalRow: billPaymentTotalRow, refetch: refetchInvoice} = useFetchTotalRow(urlIndex.bill_payment)
  // const { totalRow: payrollTotalRow, refetch: refetchPayroll } = useFetchTotalRow(urlIndex.payroll)

  // let productTotalRows = {
  //   local: localTransferTotalRow?.total_draft,
  //   reimbursement: reimbursementTotalRow?.total_drafted_reimbursement,
  //   bill: billPaymentTotalRow?.total_invoice,
  //   payroll: payrollTotalRow?.total_payroll
  // }

  useEffect(() => {
    setHeader({
      type: "close",
    });
  }, []);

  // useEffect(() => {
  //   if(!user_id) return
  //   refetchLocal()
  //   refetchReimbursement()
  //   refetchInvoice()
  //   refetchPayroll()
  // }, [user_id])

  return (
    <DraftContext.Provider value={{ toBeDeleted, setToBeDeleted }}>
      <div
        style={{
          paddingLeft: 32,
          paddingRight: 32,
          marginBottom: 24,
          gap: 8,
        }}
        className="d-flex"
      >
        <GothamMedium
          className="font24"
          style={{ color: jackColors.black34, marginRight: 16 }}
        >
          Draft
        </GothamMedium>
      </div>

      {/* TabMenu */}
      <TabMenu
        deleteQueries
        style={{ paddingLeft: 32, paddingRight: 32 }}
        // number key below is the counter badge
        menus={[
          {
            screen: (
              <DraftTabPage
                page={"local_transfer"}
                modalType={"local_disbursement_batch"}
                skeletonAmount={7}
                // refetch={refetchLocal}
                user_id={user_id}
              />
            ),
            screenName: t("Local Transfer"),
            // number: productTotalRows.local || 0,
          },
          {
            screen: (
              <DraftTabPage
                page={"reimbursement"}
                modalType={"reimbursement"}
                skeletonAmount={8}
                // refetch={refetchReimbursement}
                user_id={user_id}
              />
            ),
            screenName: "Reimbursement",
            // number: productTotalRows.reimbursement || 0,
          },
          {
            screen: (
              <DraftTabPage
                page={"bill_payment"}
                modalType={"invoice_payment"}
                skeletonAmount={9}
                // refetch={refetchInvoice}
                user_id={user_id}
              />
            ),
            screenName: t("Bill Payment"),
            // number: productTotalRows.bill || 0,
          },
          {
            screen: (
              <DraftTabPage
                page={"payroll"}
                modalType={"payroll"}
                skeletonAmount={7}
                // refetch={refetchPayroll}
                user_id={user_id}
              />
            ),
            screenName: "Payroll",
            // number: productTotalRows.payroll || 0,
          },
        ]}
      />
    </DraftContext.Provider>
  );
};
