import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
  CheckerDataDuplicate,
  checkerEmptyWhenUpload,
  DeleteStringDecider,
  isAllDataCompleted,
  OnSubmitLocalCreateFormatter,
  removeAllEmptyField,
  useLocalTransferMixFunc,
  UseLocalTransferTable,
} from "./logic";
import { FloatingButtonLocal, MainTitleLocalTable } from "./components";
import { HeaderDataTableLocal } from "./data";
import { TableCreateLocalTransfer } from "./table";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { deepUseIdsTable } from "../../../../components/Checkbox";
import { PageTogglerMulti } from "../../../../components/PageToggler/parent";
import { usePageTogglerLocal } from "./toggler";
import { SearchBarAndButton } from "./searchbar";
import { eventsTracker } from "../../../../universalFunctions/events";
import { useRouter } from "next/router";
import { windowDimension } from "../../../../components/tools";
import { ProgressBarComponent } from "../../../crossBorderComponents";
import { ProgressBarJack } from "../../../../components/Stepper";
import TabMenu from "../../../../components/TabMenu/parent";
import { jackColors } from "../../../../assets/colors";
import { ConfirmationModalJack } from "../../../../components/ButtonsJack/confirmation";
import { useModalHook } from "../../../../components/Modals";
import imgBack from "../../../../assets/images/back-previous-page.svg";
import imgDelete from "../../../../assets/images/delete-trash-grey.svg";
import imgDuplicateModal from "../../../../assets/images/duplicate-recipient.svg";
import { useHeader } from "../../../../contexts/Layout/parent";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import { ModalDelete } from "../../../PayrollCreateEnhancement/create/modal";
import { FormatterOptionsBank } from "../../../PayrollCreateEnhancement/create/formatter";
import { dummyDataLocal } from "../second";
import {
  EmptyScreenSearchImages,
  EmptyScreenSearchRecipients,
  EmptyStateSearchImages,
} from "../../../crossBorder/components";
import { stepArrayLocalFunc } from "../parent";
import { ModalConfirmationCloseLocal } from "../modal";
import CategorySelection from "../../../categoryComponents/general/components/CategorySelection";
import {
  useAvailableCategories,
  useCategoryFlag,
} from "../../../categoryComponents/general/hooks";
import { GothamMedium } from "../../../../components/Text";
import { Banner } from "../../../../components/Banner";
import ResubmitBanner from "../../../../modals/smartActivityModal/retry-resubmit/components/ResubmitBanner";
import { Trans, useTranslation } from "react-i18next";
import { translateArray } from "pageComponents/payrollCreateComponents/first";

export const CreateLocalTransfer = ({
  defaultValues,
  titleBatch: titleBatchRaw,
  loading,
  isNotFromExcel,
  category,
  onSubmit: onSubmitProps = () => {},
  onChangeCategory = () => {},
}) => {
  const { push, query } = useRouter();
  const { setHeader } = useHeader();
  const { menu, searchbox } = query || {};
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [titleBatch, setTitleBatch] = useState(false);
  const [afterAddRow, setAfterAddRow] = useState(false);
  const [isMoveToDraft, setIsMoveToDraft] = useState(false);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [isErrorCategory, setIsErrorCategory] = useState(false);
  const [selectedIdsDelete, setSelectedIdsDelete] = useState(0);
  const [isFirstScreenTable, setIsFirstScreenTable] = useState(true);
  const { isOpen: isOpenBackModal, toggle: toggleOpenBackModal } =
    useModalHook();
  const { isOpen: isOpenDeleteModal, toggle: toggleDeleteModal } =
    useModalHook();
  const { isOpen: isOpenCloseModal, toggle: toggleCloseModal } = useModalHook();
  const { isOpen: isOpenDuplicate, toggle: toggleDuplicate } = useModalHook();
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  const isResubmit = query.action === "resubmit";

  useEffect(() => {
    setHeader({
      type: "close",
      onCloseProps: () => toggleCloseModal(),
      middleComponent: (
        <ProgressBarJack
          arrayStep={translateArray({
            t: tLocalTransfer,
            array: stepArrayLocalFunc(isResubmit ? 0 : 2, isResubmit),
            key: "step",
          })}
        />
      ),
    });
  }, []);

  const { successSnackBar } = ToasterHook();
  const { headerArray, tableBodyRef } = HeaderDataTableLocal();
  const { localTransferBanks: optionsBank } = useConstants();

  const funcPropsTable = deepUseIdsTable(data);
  const { handleUncheckedAll, selectedAmount, isAllChecked } =
    funcPropsTable || {};
  const { filteredData, dataFloatingButton, isKick, isEmptyData } =
    useLocalTransferMixFunc({
      data,
      search,
    });
  const formattedOptionsBank = FormatterOptionsBank({ options: optionsBank });

  const {
    setScreen,
    isScreenIncomplete,
    dataIncompleteLength,
    isEmptyIncompleteData,
    isScreenDuplicate,
    dataDuplicateLength,
    isEmptyDuplicateData,
  } = usePageTogglerLocal({ data });

  const { hasCategoryList } = useAvailableCategories();
  const { isRequiredCategory } = useCategoryFlag();
  const isMissingRequiredCategory = Boolean(
    isRequiredCategory && !category && hasCategoryList
  );

  const handleDeleteSelected = () => {
    const filteredData = data.filter(
      (_, index) => selectedIdsDelete.indexOf(index) === -1
    );
    successSnackBar({
      msg: DeleteStringDecider(selectedAmount, isAllChecked, tLocalTransfer),
      showClose: true,
    });
    setData(isAllChecked ? [dummyDataLocal] : filteredData);
    handleUncheckedAll();
    eventsTracker("local_transfer_submit_delete_recipient");
    isAllChecked && push("/local-transfer/create");
  };

  const onSubmit = (type) => {
    const isValidate = type === "validate";
    const isDraft = type === "draft";
    const isBack = type === "back";
    const isBackModal = type === "back_modal";
    const isResubmitBack = type === "resubmit_back";
    const isOpenModalDelete = type === "open_modal_delete";
    const isSubmitDelete = type === "submit_delete";
    const isConfirmDuplicate = type === "confirm_duplicate";

    if (isResubmitBack) {
      localStorage.setItem("autoOpenTrxOrigin", query.id);
      return push({
        pathname: "/transactions",
      });
    }

    if (isSubmitDelete) return handleDeleteSelected();
    if (isOpenModalDelete) {
      return toggleDeleteModal();
    }
    if (isBack) return push("/local-transfer/create");
    if (isBackModal) return toggleOpenBackModal();

    if (isValidate || isDraft || isConfirmDuplicate) {
      if (isMissingRequiredCategory) {
        return setIsErrorCategory(true);
      }
      const dataChecker = checkerEmptyWhenUpload({ data });
      const dataClean = removeAllEmptyField({ data: dataChecker });
      const isEmptyData = dataClean.length === 0;
      const dataFinal = !!dataClean.length ? dataClean : [dummyDataLocal];
      if (isEmptyData) {
        return setData(dataFinal);
      }
      setData(dataFinal);

      const evenstTrack = isValidate
        ? "local_transfer_table_submit_validate"
        : "local_transfer_table_save_as_draft";

      const isAllCompleted = isAllDataCompleted({ data: dataClean });
      if (isAllCompleted && (isEmptyDuplicateData || isConfirmDuplicate)) {
        const finalData = OnSubmitLocalCreateFormatter(dataClean);
        onSubmitProps({
          type: isDraft || isMoveToDraft ? "draft" : "validate",
          array: isResubmit
            ? finalData.map((item) => {
                delete item.id;
                return item;
              })
            : finalData,
          batch_name: titleBatch,
        });
        return eventsTracker(evenstTrack, finalData);
      }
      if (!isEmptyIncompleteData) return setScreen("incomplete");
      if (!isEmptyDuplicateData) {
        setIsMoveToDraft(isDraft);
        return toggleDuplicate();
      }
      setScreen("incomplete");
    }
  };

  useEffect(() => {
    setTitleBatch(titleBatchRaw);
  }, [titleBatchRaw]);

  useEffect(() => {
    if (isNotFromExcel) return setData(defaultValues);

    const dataRaw = UseLocalTransferTable({
      data: defaultValues,
      headerArray,
      optionsBank,
    });
    setData(dataRaw);
  }, [Boolean(defaultValues?.length)]);

  useEffect(() => {
    if (isKick) return push("/local-transfer/create");
  }, [isKick]);

  const ref = useRef();
  const refForScroll = useRef();
  const { navbarHeight, sidebarGap } = windowDimension();

  useEffect(() => {
    if (!afterAddRow) return;
    setTimeout(() => {
      refForScroll?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }, 500);
    setAfterAddRow(false);
  }, [afterAddRow]);

  useEffect(() => {
    if (!!data.length) {
      const dataProcessed = CheckerDataDuplicate({ data });
      setData(dataProcessed);
    }
  }, [data]);

  return (
    <div style={{ margin: "0px 32px 0px 32px", position: "relative" }}>
      <div ref={ref}>
        {isResubmit && <ResubmitBanner batchName={titleBatch} />}
        <CategorySelection
          isSimple
          isError={isErrorCategory}
          selectedCategory={category}
          onSelect={(category) => {
            setIsErrorCategory(false);
            onChangeCategory(category);
          }}
        />
        {!isResubmit && (
          <MainTitleLocalTable
            title={titleBatch}
            isScreenIncomplete={isScreenIncomplete}
            dataIncompleteLength={dataIncompleteLength}
            isScreenDuplicate={isScreenDuplicate}
            dataDuplicateLength={dataDuplicateLength}
            onClick={onSubmit}
            onChangeTitle={(title) => setTitleBatch(title)}
            loading={loading}
          />
        )}
        <TabMenu
          componentsAboveScreen={
            <div>
              <SearchBarAndButton
                data={data}
                search={search}
                headerArray={headerArray}
                showDeleteRow={showDeleteRow}
                funcPropsTable={funcPropsTable}
                selectedIdsDelete={selectedIdsDelete}
                isScreenDuplicate={isScreenDuplicate}
                isScreenIncomplete={isScreenIncomplete}
                formattedOptionsBank={formattedOptionsBank}
                setData={setData}
                onChange={(e) => setSearch(e)}
                setAfterAddRow={(e) => setAfterAddRow(e)}
                setShowDeleteRow={() => setShowDeleteRow((p) => !p)}
              />
              {isEmptyData && (
                <EmptyStateSearchImages isLocalTransaction search={search} />
              )}
              <div ref={tableBodyRef} style={{ position: "relative" }}>
                <TableCreateLocalTransfer
                  headerHeight={
                    (tableBodyRef?.current?.clientHeight || 0) +
                    navbarHeight +
                    sidebarGap +
                    100
                  }
                  setData={setData}
                  setSelectedIdsDelete={setSelectedIdsDelete}
                  data={data}
                  isEmptyData={isEmptyData}
                  headerArray={headerArray}
                  filteredData={filteredData}
                  showDeleteRow={showDeleteRow}
                  funcPropsTable={funcPropsTable}
                  isScreenDuplicate={isScreenDuplicate}
                  isFirstScreenTable={isFirstScreenTable}
                  isScreenIncomplete={isScreenIncomplete}
                />
              </div>
            </div>
          }
          menus={[
            {
              screenName: tLocalTransfer("All transactions"),
              // screen: <Components />,
              screen: <></>,
            },
            ...(!!dataIncompleteLength
              ? [
                  {
                    screenName: "Incomplete",
                    // screen: <Components />,
                    screen: <></>,
                    number: dataIncompleteLength,
                    numberStyle: { background: jackColors.orangeF2 },
                  },
                ]
              : []),
            ...(!!dataDuplicateLength
              ? [
                  {
                    screenName: "Duplicate",
                    // screen: <Components />,
                    screen: <></>,
                    number: dataDuplicateLength,
                    numberStyle: { background: jackColors.orangeF2 },
                  },
                ]
              : []),
          ]}
        />
        <FloatingButtonLocal
          array={data}
          loading={loading}
          data={dataFloatingButton}
          showDeleteRow={showDeleteRow}
          funcPropsTable={funcPropsTable}
          isScreenIncomplete={isScreenIncomplete}
          isFirstScreenTable={isFirstScreenTable}
          handleSubmit={onSubmit}
          onClickOtherFields={setIsFirstScreenTable}
        />
        <ConfirmationModalJack
          onClick={() => onSubmit(isResubmit ? "resubmit_back" : "back")}
          modal={isOpenBackModal}
          toggle={toggleOpenBackModal}
          title={tLocalTransfer("Go back to the previous page?")}
          text={tLocalTransfer(
            "Going back will erase your progress and you have to upload the recipient details again."
          )}
          buttonTextRight={tLocalTransfer("Back")}
          buttonTextLeft={tLocalTransfer("Cancel ")}
          img={imgBack}
          customContentClassName="modal-border-radius4"
        />
        <ConfirmationModalJack
          onClick={() => onSubmit("confirm_duplicate")}
          modal={isOpenDuplicate}
          toggle={toggleDuplicate}
          title={tLocalTransfer("Do you want to continue?")}
          text={
            <>
              <Trans
                i18nKey={tLocalTransfer("dynamic.dynamic_text_2", {
                  dupNum: dataDuplicateLength,
                })}
                components={[<span style={{ fontFamily: "GothamMedium" }} />]}
              />
            </>
            // <>
            //   <span style={{ fontFamily: "GothamMedium" }}>
            //     {dataDuplicateLength} transactions contain duplicate data.
            //   </span>{" "}
            //   By clicking{" "}
            //   <span style={{ fontFamily: "GothamMedium" }}>Continue</span>, you
            //   acknowledge that the transaction will be sent multiple times to
            //   recipients with the amount listed in the details.
            // </>
          }
          buttonTextRight={tLocalTransfer("Confirm")}
          buttonTextLeft={tLocalTransfer("Back")}
          img={imgDuplicateModal}
          customContentClassName="modal-border-radius4"
        />
        <ConfirmationModalJack
          title={
            isAllChecked
              ? tLocalTransfer("Delete all transactions")
              : tLocalTransfer("Delete this transaction?")
          }
          modal={isOpenDeleteModal}
          img={imgDelete}
          toggle={toggleDeleteModal}
          onClick={() => onSubmit("submit_delete")}
          buttonTextRight={tLocalTransfer("Delete")}
          buttonTextLeft={tLocalTransfer("Back")}
          text={
            isAllChecked
              ? tLocalTransfer(
                  "You will delete all transactions and have to start over."
                )
              : tLocalTransfer(
                  "You will delete selected transaction from the list."
                )
          }
          customContentClassName="modal-border-radius4"
        />
        <ModalConfirmationCloseLocal
          toggle={toggleCloseModal}
          isOpen={isOpenCloseModal}
          product="local"
        />
        <div ref={refForScroll} style={{ height: 100 }} />
      </div>
    </div>
  );
};
