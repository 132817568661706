import { forwardRef } from "react";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { TextFieldJackState } from "../../../../../components/inputs/textfield";
import { useDebounce } from "../../../../../components/tools";
import styles from "./SearchDropdown.module.css";
import { useTranslation } from "react-i18next";

const SearchDropdown = forwardRef(
  (
    {
      name = "search",
      woSearch = false,
      isOpen: isExist = false,
      value = "",
      roleUpAnimationCN = "",
      roleDownAnimationCN = "",
      placeholder = "Search category name",
      className: classNameProp = "",
      children = null,
      style = {},
      onChange = () => {},
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation("category/index");
    const isOpen = useDebounce(isExist, 200);

    const baseCN = styles["search-dropdown"];
    const openAnimationCN = roleDownAnimationCN || styles["opened"];
    const closeAnimationCN = roleUpAnimationCN || styles["closed"];
    const animationCN = isExist ? openAnimationCN : closeAnimationCN;
    const className = `${baseCN} ${animationCN} ${classNameProp}`;

    const isMounted = isExist || isOpen;

    if (!isMounted) return null;

    return (
      <div ref={ref} className={className} style={style} {...props}>
        {!woSearch && (
          <TextFieldJackState
            name={name}
            value={value}
            placeholder={t(placeholder)}
            icon={<JackIcons name="search" fill={jackColors.neutral600} />}
            style={{ marginBottom: "0px", marginTop: "-6px", width: "100%" }}
            onChange={(value) => onChange(value)}
          />
        )}
        {children}
      </div>
    );
  }
);

export default SearchDropdown;
