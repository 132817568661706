import React, { useRef, useState, useEffect } from "react";
import ViewportList from "react-viewport-list";
import { colors, jackColors } from "../../../../assets/colors";
import { CheckBox } from "../../../../components/Checkbox";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { windowDimension } from "../../../../components/tools";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import {
  isDuplicateOnDataTable,
  isErrorOnDataTable,
} from "../../../PayrollCreateEnhancement/create/logic";
import { ModalDelete } from "../../../PayrollCreateEnhancement/create/modal";
import {
  defaultOnChangeTablePayroll,
  ModalEditSelectTable,
  ModalEditTable,
} from "../../../PayrollCreateEnhancement/create/table/modal";
import { renderedValue, useBooleanTableLocal } from "./logic";
import editIcon from "../../../../assets/images/edit-outline.svg";
import imgDelete from "../../../../assets/images/delete-trash-grey.svg";
import { TooltipEdit } from "../../../PayrollCreateEnhancement/create/tooltip";
import { useModalHook } from "../../../../components/Modals";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import { eventsTracker } from "../../../../universalFunctions/events";
import { EmailInput } from "./inputs/email/parent";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { ConfirmationModalJack } from "../../../../components/ButtonsJack/confirmation";
import { dummyDataLocal } from "../second";
import CustomHoverInfo from "../../../../components/CustomHoverInfo/CustomHoverInfo";
import { useRouter } from "next/router";
import { Trans, useTranslation } from "react-i18next";

export const TableCreateLocalTransfer = ({
  style,
  headerArray,
  data,
  setData,
  isEmptyData,
  filteredData,
  isFirstScreenTable,
  isScreenDuplicate,
  isScreenIncomplete,
  funcPropsTable,
  showDeleteRow,
  setSelectedIdsDelete = () => {},
  headerHeight,
}) => {
  const [modalIndex, setModalIndex] = useState([]);
  const [indexDelete, setIndexDelete] = useState(false);
  const { successSnackBar, successToaster, errorToaster } = ToasterHook();
  const { t: tLocalTransaction } = useTranslation(
    "local-transfer/local-transfer"
  );

  const {
    isOpen: isOpenDelete,
    close: closeDelete,
    toggle: toggleDelete,
  } = useModalHook();
  const {
    isOpen: isOpenModalEdit,
    close: closeModalEdit,
    toggle: toggleModalEdit,
  } = useModalHook();

  const { screenRealHeight } = windowDimension();
  const refScrollBottom = useRef(null);
  const refTable = useRef(null);

  const { selectedIds, handleUncheckedAll } = funcPropsTable || {};
  const isAnythingSelected = !!selectedIds?.length;
  const isDataBelow5 = data?.length <= 5;

  const handleClick = (data, item, index, childIndex) => {
    const { name } = item;
    const { isAction, isNumber, isDelete } = useBooleanTableLocal({ name });

    if (isNumber || isDelete) return;
    if (isAction) {
      setIndexDelete(index);
      toggleDelete();
      return;
    }
    return handleEdit(item, index, childIndex);
  };

  const handleEdit = (item, index, childIndex) => {
    toggleModalEdit();
    eventsTracker("local_transfer_table_open_modal_edit_recipient");
    setModalIndex([index, childIndex]);
  };

  const handleDelete = () => {
    const isOnlyOneData = data?.length === 1;
    const filteredData = data.filter((item, index) => index !== indexDelete);
    const deletedData = data.filter((_, index) => index == indexDelete);
    // toggleDelete();
    successSnackBar({
      msg: (
        <Trans
          i18nKey={tLocalTransaction("dynamic.dynamic_text_6", {
            dupNum: deletedData?.length,
          })}
        />
      ),
      showClose: true,
    });
    handleUncheckedAll();
    eventsTracker("local_transfer_table_delete_single_recipient", deletedData);
    if (isOnlyOneData) return setData([dummyDataLocal]);
    setData(filteredData);
  };

  useEffect(() => {
    setSelectedIdsDelete(selectedIds);
  }, [selectedIds]);

  const [scrollTop, setScrollTop] = useState(0);

  if (isEmptyData) return null;

  return (
    <div
      ref={refScrollBottom}
      style={{
        // paddingBottom: isScreenIncomplete || showDeleteRow ? 420 : 320,
        // overflow: "scroll",
        position: "relative",
        ...style,
      }}
      onScroll={(e) => {
        if (!e) return;
        const { scrollTop } = e?.target || {};
        setScrollTop(scrollTop);
      }}
    >
      <table
        ref={refTable}
        style={{
          width: "100%",
          // overflow: "scroll",
          position: "relative",
          tableLayout: "fixed",
          wordBreak: "break-word",
          border: `1px solid ${jackColors.neutral500}`,
          borderCollapse: "separate",
          backgroundColor: "white",
          padding: "8px 8px 0px 8px",
          borderRadius: 8,
          marginBottom: isDataBelow5 ? 200 : 32,
        }}
        cellSpacing={0}
      >
        <TheadTableLocal
          headerArray={headerArray}
          funcPropsTable={funcPropsTable}
          showDeleteRow={showDeleteRow}
          isFirstScreenTable={isFirstScreenTable}
          isScreenIncomplete={isScreenIncomplete}
          isAnythingSelected={isAnythingSelected}
        />
        <TbodyTableLocal
          scrollTop={scrollTop}
          isAnythingSelected={isAnythingSelected}
          headerHeight={headerHeight}
          data={data}
          setData={setData}
          modalIndex={modalIndex}
          showDeleteRow={showDeleteRow}
          filteredData={filteredData}
          headerArray={headerArray}
          renderFirst={isFirstScreenTable}
          funcPropsTable={funcPropsTable}
          isScreenDuplicate={isScreenDuplicate}
          isScreenIncomplete={isScreenIncomplete}
          isOpenDelete={isOpenDelete}
          toggleDelete={toggleDelete}
          handleClick={handleClick}
          confirmDelete={handleDelete}
          isOpenModalEdit={isOpenModalEdit}
          closeModalEdit={closeModalEdit}
          toggleModalEdit={toggleModalEdit}
          tLocalTransaction={tLocalTransaction}
        />
      </table>
    </div>
  );
};

export const TheadTableLocal = ({
  headerArray,
  funcPropsTable,
  isAnythingSelected,
  isScreenIncomplete,
  isFirstScreenTable,
  showDeleteRow,
  fromModal,
}) => {
  const { isAllChecked, handleClickAll } = funcPropsTable || {};
  const { t } = useTranslation("local-transfer/local-transfer");
  const { query } = useRouter();
  const isResubmit = query.action === "resubmit";

  return (
    <thead
      style={{
        // position: "sticky",
        top: 0,
        // border: "none",
        zIndex: 1,
        backgroundColor: "white",
      }}
    >
      <tr>
        {headerArray.map((item, index) => {
          const { name, value, width } = item || {};
          const {
            isName,
            isNumber,
            isBank,
            isAction,
            isDelete,
            isAccNumber,
            isAmount,
            isNotes,
            isFirstRender,
            isSecondRender,
          } = useBooleanTableLocal({ name });
          const isHideButton = isNumber || isAction;

          const MaskingLabel = (value) => {
            if (isName) return t("Recipient");
            if (isBank) return t("Bank Name");
            if (isAccNumber) return t("Account Number");
            if (isNotes) return t("Transfer Note");
            if (isAmount) return t("Amount");
            if (isAction) return "";
            return value;
          };
          {
            /* if (isFirstScreenTable && isSecondRender) return null;
          if (!isFirstScreenTable && isFirstRender) return null; */
          }
          if ((isAction || isDelete) && isResubmit) return null;

          const renderedValue = () => {
            if (isDelete) {
              if (fromModal || isScreenIncomplete) return null;
              if (isAnythingSelected) {
                return (
                  <div
                    className="text-center"
                    style={{
                      maxHeight: 20,
                    }}
                  >
                    <CheckBox
                      fillCustom={
                        isAllChecked
                          ? jackColors.neutral900
                          : jackColors.neutral600
                      }
                      isActive={isAllChecked}
                      onClick={handleClickAll}
                      style={{ width: 16, height: 16 }}
                    />
                  </div>
                );
              }
              return "";
            }
            return (
              <GothamRegular
                className="font12"
                style={{
                  color: jackColors.neutral700,
                  textAlign: isAmount && "end",
                }}
              >
                {MaskingLabel(value)}
              </GothamRegular>
            );
          };

          if (fromModal && isDelete) return null;
          if (!fromModal && isNumber) return null;
          return (
            <th
              key={index}
              style={{
                padding: `${fromModal ? "20px" : "12px"} 8px 11.5px 8px`,
                color: colors.grey6c,
                // borderBottom: `1px solid ${jackColors.neutral500}`,
                // whiteSpace: "nowrap",
                // overflow: "hidden",
                width: width,
                // maxWidth: width,
                textAlign: isHideButton && "center",
                position: "relative",
              }}
            >
              {renderedValue()}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export const TbodyTableLocal = ({
  scrollTop,
  headerHeight,
  data,
  setData,
  filteredData,
  headerArray,
  renderFirst,
  modalIndex,
  funcPropsTable,
  showDeleteRow,
  isScreenDuplicate,
  isScreenIncomplete,
  isOpenDelete,
  toggleDelete,
  handleClick,
  confirmDelete,
  isOpenModalEdit,
  closeModalEdit,
  isAnythingSelected,
}) => {
  const [isHover, setIsHover] = useState(false);
  const [isHoverTr, setHoverTr] = useState(false);
  const [hoveredErrorInfo, setHoveredErrorInfo] = useState("");
  const [validResubmitFields, setValidResubmitFields] = useState({});
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  const { query } = useRouter();
  const isResubmit = query.action === "resubmit";

  const ref = useRef(null);

  const { handleClickItem, selectedIds } = funcPropsTable || {};

  // const filteredArrNumbers = filteredData?.map((array) => array[2]?.value);

  const { localTransferBanks: optionsBank } = useConstants();

  // const newArr = data?.map((item) => {
  //   if (!item) return undefined;
  //   const value = item[2]?.value;
  //   if (filteredArrNumbers.includes(value)) return item;
  //   return undefined;
  // });

  return (
    <tbody ref={ref}>
      <ViewportList
        viewportRef={ref}
        items={filteredData}
        itemMinSize={100}
        margin={8}
        overscan={10}
      >
        {(array, index) => {
          if (!array) return null;
          const isActive = (selectedIds || []).includes(index);
          const isThereError = isErrorOnDataTable({ array });
          const isThereDuplicate = isDuplicateOnDataTable({ array });
          const isHovered = isHoverTr === index;
          const isFirst = index === 0;

          if (isScreenIncomplete && !isThereError) return <tr></tr>;
          if (isScreenDuplicate && !isThereDuplicate) return <tr></tr>;

          const bgColorDecider = () => {
            if (isScreenDuplicate || isScreenIncomplete) {
              if (isHovered) return jackColors.neutral500;
              return "transparent";
            }
            if (isThereError && !isScreenIncomplete) return "#FFEBE8";
            if (isThereDuplicate && !isScreenDuplicate) return "#FFF6E6";
            if (isHovered) return jackColors.neutral500;
          };

          return (
            <tr
              key={index}
              style={{
                transition: "all 300ms",
                backgroundColor: bgColorDecider(),
                height: 64,
              }}
              onMouseEnter={() => setHoverTr(index)}
              onMouseLeave={() => setHoverTr(false)}
            >
              {array.map((item, childIndex) => {
                if (!item) return <td>-</td>;
                const { name, value } = item;
                const getWidth = headerArray.filter(
                  ({ name: nameTd }) => nameTd === name
                )[0]?.maxWidth;
                const doubleIndex = [index, childIndex];
                const isIndexSame =
                  JSON.stringify(doubleIndex) === JSON.stringify(modalIndex);
                const isHoverIndexSame =
                  JSON.stringify(doubleIndex) === JSON.stringify(isHover);
                const isShowIcon = isHoverIndexSame ? "initial" : "none";
                const {
                  isNumber,
                  isName,
                  isBank,
                  isNotes,
                  isAction,
                  isEmail,
                  isAmount,
                  isDelete,
                  isId,
                  isLocalId,
                  isRequired,
                  isBorderRight,
                  isAccNumber,
                } = useBooleanTableLocal({ name });
                const isHideButton = isNumber || isAction || isDelete;
                const isSelectModal = isBank;

                const finalValue = renderedValue({
                  item,
                  index,
                  showDeleteRow,
                  isActive,
                  handleClickItem,
                  options: optionsBank,
                  isHover: isHovered,
                  isAnythingSelected,
                  tLocalTransfer,
                });

                const styleCustom = {
                  maxWidth: getWidth,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                };

                if (isId || isLocalId || isNumber) return null;

                if ((isDelete || isAction) && isResubmit) return null;

                const modalDecider = () => {
                  const showModal = isOpenModalEdit && isIndexSame;

                  const onEditConfirm = () => {
                    const makeValid = (fieldName) => {
                      return setValidResubmitFields((prev) => ({
                        ...prev,
                        [fieldName]: true,
                      }));
                    };

                    if (isBank) return makeValid(`bank_${index}`);
                    if (isAccNumber) return makeValid(`account_${index}`);
                  };

                  const props = {
                    data,
                    item,
                    setData,
                    index,
                    childIndex,
                    close: closeModalEdit,
                    isLocalTransfer: true,
                    getWidth: getWidth * (3 / 2),
                    onEditConfirm,
                  };
                  if (!showModal) return null;

                  if (isSelectModal)
                    return (
                      <ModalEditSelectTable {...props} required={isRequired} />
                    );

                  if (isEmail)
                    return (
                      <EmailInput
                        index={index}
                        scrollHeight={scrollTop}
                        headerHeight={headerHeight}
                        trHeight={46}
                        onlyModal
                        value={value}
                        close={closeModalEdit}
                        isLast={index > 1 && index >= filteredData.length - 3}
                        setValue={(val) =>
                          defaultOnChangeTablePayroll({
                            setData,
                            index,
                            name,
                            value: val,
                            woInvalid: true,
                          })
                        }
                      />
                    );

                  return <ModalEditTable notRequired={isNotes} {...props} />;
                };

                return (
                  <td
                    key={childIndex}
                    style={{
                      maxWidth: getWidth,
                      padding: "12px 8px",
                      position: "relative",
                      // borderTop:
                      //   !isFirst && `1px solid ${jackColors.neutral500}`,
                      borderTop: `1px solid ${jackColors.neutral500}`,
                      // borderRight: isBorderRight && "1px solid #E6E6E8",
                    }}
                    onMouseEnter={() => setIsHover(doubleIndex)}
                    onMouseLeave={() => setIsHover(false)}
                  >
                    <div
                      style={{
                        position: "relative",
                        cursor: !isNumber && "pointer",
                      }}
                      className={`d-flex ${
                        isHideButton
                          ? "justify-content-center"
                          : isAmount && "justify-content-end"
                      }`}
                      onClick={() => handleClick(data, item, index, childIndex)}
                    >
                      {isResubmit &&
                        ((isBank && !validResubmitFields[`bank_${index}`]) ||
                          (isAccNumber &&
                            !validResubmitFields[`account_${index}`])) && (
                          <div
                            style={{ position: "relative" }}
                            onMouseEnter={() =>
                              setHoveredErrorInfo(`${name}_${index}`)
                            }
                            onMouseLeave={() => setHoveredErrorInfo("")}
                          >
                            <JackIcons
                              name="alert-circle-outline"
                              fill={jackColors.redE7}
                              style={{
                                scale: "0.8",
                                translate: "0px -2px",
                                marginRight: "4px",
                              }}
                            />
                            <CustomHoverInfo
                              show={hoveredErrorInfo === `${name}_${index}`}
                              style={{
                                width: "320px",
                                padding: "12px 8px",
                                translate: "-142px -80px",
                                flexDirection: "column",
                                gap: "8px",
                              }}
                              translateTrianglePoint="150px 38px"
                              rotateTrianglePoint="-90deg"
                              backgroundColor="white"
                            >
                              <GothamRegular
                                woFontColor
                                className="font12"
                                style={{ color: jackColors.neutral800 }}
                              >
                                Please recheck and update the{" "}
                                <span style={{ fontFamily: "GothamMedium" }}>
                                  {isBank
                                    ? "bank details"
                                    : isAccNumber
                                    ? "account number"
                                    : ""}
                                </span>
                                .
                              </GothamRegular>
                            </CustomHoverInfo>
                          </div>
                        )}
                      {!isHideButton && isAmount && (
                        <TooltipEdit>
                          <img
                            src={editIcon}
                            style={{
                              display: isShowIcon,
                              width: 20,
                              marginRight: 8,
                            }}
                          />
                        </TooltipEdit>
                      )}
                      <GothamRegular
                        style={{
                          color: jackColors.neutral900,
                          ...styleCustom,
                        }}
                      >
                        {finalValue}
                      </GothamRegular>
                      {!isHideButton && !isAmount && (
                        <TooltipEdit>
                          <img
                            src={editIcon}
                            style={{
                              display: isShowIcon,
                              width: 20,
                              marginLeft: 8,
                            }}
                          />
                        </TooltipEdit>
                      )}
                    </div>
                    {modalDecider()}
                  </td>
                );
              })}
            </tr>
          );
        }}
      </ViewportList>
      <ConfirmationModalJack
        title={tLocalTransfer("Delete this transaction?")}
        modal={isOpenDelete}
        toggle={toggleDelete}
        onClick={confirmDelete}
        img={imgDelete}
        buttonTextRight={tLocalTransfer("Delete")}
        buttonTextLeft={tLocalTransfer("Back")}
        text={tLocalTransfer("You will delete this transaction from the list.")}
        customContentClassName="modal-border-radius4"
      />
    </tbody>
  );
};
