import {
  dateOnlyGetHour24Format,
  digitDateFormatterReversed,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { SubTextTableComponent } from "../../../components/Table/components";
import { GothamRegular } from "../../../components/Text";
import { useFetchRow } from "./useFetchRow";
import { useContext, useEffect, useRef } from "react";
import { useTransactionFiltersArray } from "../../transactionsComponents/transactions/logics/filterArray";
import ParserRichTextArea from "../../../components/ParserRichTextArea";
import CheckboxSquare from "../../alertsAndAttentionComponents/components/CheckboxSquare";
import { DraftContext } from "../parent";
import { draftTotalAmount } from "../../reimbursementComponents/data/formatter";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

const tableTotalWidth = 1136;
const tableWidth = (number) => `${(number / tableTotalWidth) * 100}%`;

export const localRowFormat = (row) => {
  const {
    updated_at,
    name,
    user,
    total_amount,
    total_fee,
    id: trxId,
  } = row || {};

  const updatedAtFormatted = dateFormatter(updated_at);
  const userFormatted = gothamizedText(user?.name);
  const batchNameFormatted = gothamizedText(name);
  const amountPlusFee = Number(total_amount) + Number(total_fee);
  const totalAmountFormatted = gothamizedText(
    "IDR " + formatCurrencyNoDecimal(amountPlusFee),
    "right"
  );

  const { toBeDeleted, setToBeDeleted } = useContext(DraftContext);
  const isChecked = !!toBeDeleted.find(({ id }) => id === trxId);

  const handleClick = () => {
    setToBeDeleted((prev) => {
      const isPreviouslySelected = prev.find(({ id }) => id === trxId);

      if (isPreviouslySelected) {
        return prev.filter(({ id }) => id !== trxId);
      }

      return [...prev, row];
    });
  };

  return [
    <CheckboxSquare isChecked={isChecked} onClick={handleClick} />,
    <SubTextTableComponent
      text={updatedAtFormatted.dateOnly}
      subText={updatedAtFormatted.timeOnly}
    />,
    batchNameFormatted,
    userFormatted,
    totalAmountFormatted,
  ];
};

export const reimbursementRowFormat = (row) => {
  const {
    updated_at,
    name, // reimbursement name
    local_recipient_data, // recipient
    reimbursement_child, // array of trx
    amount, // total amount? ini jumlah smua amount dari reimbursement_child
    id: trxId,
  } = row || {};

  const displayedAmount = draftTotalAmount(reimbursement_child);
  const updatedAtFormatted = dateFormatter(updated_at);
  const recipientFormatted = gothamizedText(
    local_recipient_data?.registered_name
  );
  const bankName = local_recipient_data?.bank_name;
  const bankNumber = local_recipient_data?.account_number;
  const recipientSubText = `${bankName} - ${bankNumber}`;
  const numberExpense = gothamizedText(reimbursement_child?.length);
  const reimbursementNameFormatted = gothamizedText(name);
  const totalAmountFormatted = gothamizedText(displayedAmount, "right");

  const { toBeDeleted, setToBeDeleted } = useContext(DraftContext);
  const isChecked = !!toBeDeleted.find(({ id }) => id === trxId);

  const handleClick = () => {
    setToBeDeleted((prev) => {
      const isPreviouslySelected = prev.find(({ id }) => id === trxId);

      if (isPreviouslySelected) {
        return prev.filter(({ id }) => id !== trxId);
      }

      return [...prev, row];
    });
  };

  return [
    <CheckboxSquare isChecked={isChecked} onClick={handleClick} />,
    <SubTextTableComponent
      text={updatedAtFormatted.dateOnly}
      subText={updatedAtFormatted.timeOnly}
    />,
    reimbursementNameFormatted,
    <SubTextTableComponent
      text={recipientFormatted}
      subText={bankName ? recipientSubText : ""}
    />,
    numberExpense,
    totalAmountFormatted,
  ];
};

export const billRowFormat = (row) => {
  const {
    updated_at,
    invoice_number,
    local_recipient, // payment to
    due_date,
    description,
    total_amount = 0,
    id: trxId,
  } = row || {};

  const updatedAtFormatted = dateFormatter(updated_at);
  const invoiceNumFormatted = gothamizedText(invoice_number);
  const recipientFormatted = gothamizedText(local_recipient?.name);
  const dueDateFormatted = dateFormatter(due_date)?.dateOnly;
  const descriptionText = description ? String(description) : "-";
  const descriptionFormatted = gothamizedText(
    <ParserRichTextArea text={descriptionText} />
  );
  const totalAmountFormatted = gothamizedText(
    "IDR " + formatCurrencyNoDecimal(total_amount),
    "right"
  );

  const { toBeDeleted, setToBeDeleted } = useContext(DraftContext);
  const isChecked = !!toBeDeleted.find(({ id }) => id === trxId);

  const handleClick = () => {
    setToBeDeleted((prev) => {
      const isPreviouslySelected = prev.find(({ id }) => id === trxId);

      if (isPreviouslySelected) {
        return prev.filter(({ id }) => id !== trxId);
      }

      return [...prev, row];
    });
  };

  return [
    <CheckboxSquare isChecked={isChecked} onClick={handleClick} />,
    <SubTextTableComponent
      text={updatedAtFormatted.dateOnly}
      subText={updatedAtFormatted.timeOnly}
    />,
    invoiceNumFormatted,
    recipientFormatted,
    dueDateFormatted,
    descriptionFormatted,
    totalAmountFormatted,
  ];
};

export const payrollRowFormat = (row) => {
  const {
    updated_at,
    name, // batch name
    batch,
    drafter,
    user,
    id: trxId,
  } = row || {};

  const updatedAtFormatted = dateFormatter(updated_at);
  const batchNameFormatted = gothamizedText(name);
  const createdByFormatted = gothamizedText(drafter?.name);
  const totalAndFee = Number(batch?.total_amount) + Number(batch?.total_fee);
  const totalAmountFormatted = gothamizedText(
    "IDR " + formatCurrencyNoDecimal(totalAndFee),
    "right"
  );

  const { toBeDeleted, setToBeDeleted } = useContext(DraftContext);
  const isChecked = !!toBeDeleted.find(({ id }) => id === trxId);

  const handleClick = () => {
    setToBeDeleted((prev) => {
      const isPreviouslySelected = prev.find(({ id }) => id === trxId);

      if (isPreviouslySelected) {
        return prev.filter(({ id }) => id !== trxId);
      }

      return [...prev, row];
    });
  };

  return [
    <CheckboxSquare isChecked={isChecked} onClick={handleClick} />,
    <SubTextTableComponent
      text={updatedAtFormatted.dateOnly}
      subText={updatedAtFormatted.timeOnly}
    />,
    batchNameFormatted,
    createdByFormatted,
    totalAmountFormatted,
  ];
};

export const dateFormatter = (date) => {
  if (!date) return "-";
  const dateObj = parseISO(date);
  const dateOnly = format(dateObj, "dd/MM/yy");
  const timeOnly = format(dateObj, "HH:mm");
  return { dateOnly, timeOnly };
};

export const gothamizedText = (text, align = "left") => {
  if (!text) return "-";
  return (
    <GothamRegular
      style={{
        textAlign: align,
        maxWidth: "90%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {text}
    </GothamRegular>
  );
};

export const bodyDecider = (items, currentTab) => {
  switch (currentTab) {
    case "local_transfer":
      return localRowFormat(items);
    case "reimbursement":
      return reimbursementRowFormat(items);
    case "bill_payment":
      return billRowFormat(items);
    case "payroll":
      return payrollRowFormat(items);
    default:
      return undefined;
  }
};

export const tableFormer = ({ menuForTable, user_id }) => {
  const { t } = useTranslation("draft/draft");
  const {
    data: rawDataOnly,
    nextPage,
    loading,
    refetch,
    afterDelete,
  } = useFetchRow({ menuForTable, user_id });
  const { toBeDeleted, setToBeDeleted } = useContext(DraftContext);
  const isChecked =
    toBeDeleted.length === rawDataOnly.length && rawDataOnly.length > 0;

  const handleClick = () => setToBeDeleted(isChecked ? [] : [...rawDataOnly]);

  const tableIndex = {
    local_transfer: [
      {
        label: <CheckboxSquare isChecked={isChecked} onClick={handleClick} />,
        width: tableWidth(40),
      },
      {
        label: t("Last Saved"),
        width: tableWidth(94),
      },
      {
        label: t("Batch Name"),
        width: tableWidth(786),
      },
      {
        label: t("Created By"),
        width: tableWidth(200),
      },
      {
        label: t("Total Amount"),
        width: tableWidth(200),
        textAlign: "right",
      },
      {
        label: "",
        width: tableWidth(40),
        textAlign: "center",
      },
    ],
    reimbursement: [
      {
        label: <CheckboxSquare isChecked={isChecked} onClick={handleClick} />,
        width: tableWidth(40),
      },
      {
        label: t("Last Saved"),
        width: tableWidth(94),
        textAlign: "left",
      },
      {
        label: t("Reimbursement Name"),
        width: tableWidth(658),
        textAlign: "left",
      },
      {
        label: t("Recipient"),
        width: tableWidth(240),
        textAlign: "left",
      },
      {
        label: t("Number of expense"),
        width: tableWidth(140),
        textAlign: "left",
      },
      {
        label: t("Total Amount"),
        width: tableWidth(148),
        textAlign: "right",
      },
      {
        label: "",
        width: tableWidth(40),
        textAlign: "center",
      },
    ],
    bill_payment: [
      {
        label: <CheckboxSquare isChecked={isChecked} onClick={handleClick} />,
        width: tableWidth(40),
      },
      {
        label: t("Last Saved"),
        width: tableWidth(94),
        textAlign: "left",
      },
      {
        label: t("Invoice Number"),
        width: tableWidth(200),
        textAlign: "left",
      },
      {
        label: t("Payment to"),
        width: tableWidth(200),
        textAlign: "left",
      },
      {
        label: t("Due Date"),
        width: tableWidth(160),
        textAlign: "left",
      },
      {
        label: t("Description"),
        width: tableWidth(466),
        textAlign: "left",
      },
      {
        label: t("Invoice Amount"),
        width: tableWidth(160),
        textAlign: "right",
      },
      {
        label: "",
        width: tableWidth(40),
        textAlign: "center",
      },
    ],
    payroll: [
      {
        label: <CheckboxSquare isChecked={isChecked} onClick={handleClick} />,
        width: tableWidth(40),
      },
      {
        label: t("Last Saved"),
        width: tableWidth(94),
        textAlign: "left",
      },
      {
        label: t("Batch Name"),
        width: tableWidth(786),
        textAlign: "left",
      },
      {
        label: t("Created By"),
        width: tableWidth(200),
        textAlign: "left",
      },
      {
        label: t("Total Amount"),
        width: tableWidth(200),
        textAlign: "right",
      },
      {
        label: "",
        width: tableWidth(40),
        textAlign: "center",
      },
    ],
  };

  const tbodyRef = useRef(null);
  const headerArr = tableIndex[menuForTable];
  const activeFilters = ["date"];
  const filters = useTransactionFiltersArray("mine");
  const filterProps = { activeFilters, filters, woAdd: true };

  let bodyTable = rawDataOnly.map((item) => {
    return {
      rawData: item,
      tableRow: item ? bodyDecider(item, menuForTable) : [],
    };
  });

  useEffect(() => {
    bodyTable = [];
  }, [menuForTable]);

  return {
    headerArr,
    tbodyRef,
    filterProps,
    bodyTable,
    nextPage,
    loading,
    refetch,
    afterDelete,
  };
};

export const rowDuplicateFilter = (arr) => {
  const ids = new Set();
  return arr.filter((obj) => {
    const id = obj?.id;
    if (ids.has(id)) {
      return false;
    } else {
      ids.add(id);
      return true;
    }
  });
};
