import { useRouter } from "next/router";
import { useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { colors, jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { Banner } from "../../../../components/Banner";
import { ConfirmationModalJack } from "../../../../components/ButtonsJack/confirmation";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { MinimalistTextField } from "../../../../components/inputs";
import { useModalHook } from "../../../../components/Modals";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { formatCurrencyNoDecimal } from "../../../../components/tools";
import imgDraft from "../../../../public/images/draft-transaction.svg";
import { useClickOutside } from "../../../../universalFunctions/useClickOutside";
import { pluralize } from "../../../invoiceComponents/data/formatter";
import { ValidationValueContainWhiteSpace } from "../../../payrollCreateComponents/logics";
import { isThereIncompleteData } from "../../../PayrollCreateEnhancement/create/logic";
import { useBooleanTableLocal } from "./logic";

export const MainTitleLocalTable = ({
  data,
  title,
  onClick,
  loading,
  isPayroll = false,
  onChangeTitle,
  isScreenIncomplete,
  isScreenDuplicate,
  dataIncompleteLength,
  dataDuplicateLength,
}) => {
  const { push, pathname } = useRouter();
  const isLocalTransfer = pathname.includes("/local-transfer/create");
  const { isOpen: isOpenSaveDraft, toggle: toggleSaveDraft } = useModalHook();
  const { t } = useTranslation("payroll/create");
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  const tDecider = isLocalTransfer ? tLocalTransfer : t;
  const tData = {
    trxNum: dataIncompleteLength,
    trx: pluralize(dataIncompleteLength, "transaction"),
  };
  const tKeyEdgeCondition = !isLocalTransfer
    ? t("dynamic.dynamic_text_2", tData)
    : tLocalTransfer("dynamic.dynamic_text_1", tData);

  const {
    isOpen: isOpenEditTitle,
    toggle: toggleEditTitle,
    close: closeEditTitle,
  } = useModalHook();

  return (
    <div>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ marginBottom: 24 }}
      >
        <div
          className="d-flex align-items-center"
          style={{ position: "relative" }}
        >
          <GothamMedium
            className="font20"
            style={{ color: jackColors.neutral900 }}
          >
            {title}
          </GothamMedium>
          <JackIcons
            onClick={toggleEditTitle}
            name="edit-outline"
            fill={jackColors.neutral900}
            style={{ width: 20, height: 20, marginLeft: 16, cursor: "pointer" }}
          />
          {isOpenEditTitle && (
            <ModalEditTitle
              close={closeEditTitle}
              containerStyle={{ width: 363 }}
              value={title}
              onSubmit={onChangeTitle}
            />
          )}
        </div>
        <ButtonJack
          isLoading={loading}
          onClick={toggleSaveDraft}
          type="outline"
          leftIcon={
            <JackIcons
              name="inbox-outline"
              style={{ width: 20, height: 20 }}
              fill={loading ? jackColors.neutral500 : jackColors.neutral900}
            />
          }
          style={{ height: 32, padding: 6 }}
        >
          {t("Move to Draft")}
        </ButtonJack>
      </div>
      {isScreenIncomplete && (
        <div style={{ marginBottom: 24 }}>
          <Banner
            type="error"
            title={<Trans i18nKey={tKeyEdgeCondition} />}
            titleStyle={{ fontSize: 14, lineHeight: "20px" }}
            iconStyle={{ marginTop: -14 }}
            msg={
              <div>
                <Trans
                  i18nKey={t("dynamic.style_text_1")}
                  components={[<span style={{ fontFamily: "GothamMedium" }} />]}
                />
              </div>
            }
          />
        </div>
      )}
      {isScreenDuplicate && (
        <div style={{ marginBottom: 24 }}>
          <Banner
            type="warning"
            title={
              <Trans
                i18nKey={tDecider("dynamic.dynamic_text_3", {
                  dupNum: dataDuplicateLength,
                })}
              />
            }
            titleStyle={{ fontSize: 14, lineHeight: "20px" }}
            iconStyle={{ marginTop: -14 }}
            msg={
              <Trans
                i18nKey={tDecider("dynamic.dynamic_text_4", {
                  dupNum: dataDuplicateLength,
                })}
              />
            }
          />
        </div>
      )}
      <ConfirmationModalJack
        onClick={() => onClick(isPayroll ? { type: "draft" } : "draft")}
        modal={isOpenSaveDraft}
        toggle={toggleSaveDraft}
        title={t("Move this request to draft?")}
        text={
          <>
            <Trans
              i18nKey={t("dynamic.style_text_2")}
              components={[<span style={{ fontFamily: "GothamMedium" }} />]}
            />
          </>
        }
        buttonTextLeft={t("Back")}
        buttonTextRight={t("Move to Draft")}
        img={imgDraft}
        customContentClassName="modal-border-radius4"
        isCenteredTitle
      />
    </div>
  );
};

export const FloatingButtonLocal = ({
  data,
  array,
  funcPropsTable,
  isConfirmationPage,
  handleSubmit = () => {},
  loading,
  showDeleteRow,
}) => {
  const { selectedAmount, handleUncheckedAll, handleRemoveAllId } =
    funcPropsTable;
  const UncheckedAll = handleUncheckedAll || handleRemoveAllId;
  const {
    totalTrx,
    totalAmount: totalAmountRaw,
    totalFee: totalFeeRaw,
  } = data || {};
  const totalAmount = formatCurrencyNoDecimal(totalAmountRaw);
  const totalFee = formatCurrencyNoDecimal(totalFeeRaw || 0);
  const amountPlusFee = formatCurrencyNoDecimal(
    Number(totalAmountRaw || 0) + Number(totalFeeRaw || 0)
  );

  const isStillThereIncomplete = isThereIncompleteData({ data: array });
  const wordingButton = isConfirmationPage ? "Submit" : "Validate";
  const hideBigWhiteBar = showDeleteRow;
  const { t } = useTranslation("local-transfer/local-transfer");

  const dataRendered = [
    {
      title: t("Number of Transaction"),
      desc: `${totalTrx} ${t(pluralize(totalTrx, "Transaction"))}`,
    },
    ...(isConfirmationPage
      ? [
          {
            title: "Total Fee",
            desc: `IDR ${formatCurrencyNoDecimal(
              totalFeeRaw / totalTrx
            )} x ${totalTrx} = IDR ${totalFee}`,
          },
        ]
      : []),
    {
      title: t("Total Amount"),
      desc: `IDR ${totalAmount}`,
    },
  ];

  return (
    <>
      <div
        style={{
          position: "fixed",
          bottom: -10,
          left: "50%",
          transform: "translate(-50%, -50%)",
          // zIndex: 1,
          // margin: "10px 30px",
          width: 900,
          backgroundColor: jackColors.neutral900,
          boxShadow:
            "0px 4px 12px -4px rgba(22, 34, 51, 0.12), 0px 16px 32px rgba(22, 34, 51, 0.16)",
          borderRadius: 8,
          padding: selectedAmount ? "20px 24px" : "16px 24px",
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          {selectedAmount ? (
            <GothamMedium
              className="font16"
              style={{ color: jackColors.neutral100 }}
            >
              {selectedAmount} {t(pluralize(selectedAmount, "transaction"))}{" "}
              {t("selected")}
            </GothamMedium>
          ) : (
            <div className="d-flex">
              {dataRendered?.map(({ title, desc }, index) => {
                const isLast = index == dataRendered.length - 1;
                return (
                  <div key={index} className="d-flex">
                    <div>
                      <GothamRegular
                        className="font12"
                        style={{
                          color: jackColors.neutral100,
                          marginBottom: 8,
                        }}
                      >
                        {title}
                      </GothamRegular>
                      <GothamMedium
                        className="font16"
                        style={{ color: jackColors.neutral100 }}
                      >
                        {desc}
                      </GothamMedium>
                    </div>
                    {!isLast && (
                      <div
                        style={{
                          borderLeft: `1px solid ${colors.greye6}`,
                          margin: "0px 20px",
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
          <div className="d-flex" style={{ gap: 12 }}>
            <ButtonJack
              type="outline-transparent"
              children={selectedAmount ? t("Cancel") : t("Back")}
              style={{ minWidth: 65 }}
              onClick={() =>
                selectedAmount ? UncheckedAll() : handleSubmit("back_modal")
              }
              leftIcon={
                !selectedAmount && (
                  <JackIcons
                    name="arrow-back-outline"
                    fill="white"
                    style={{ width: 20, height: 20 }}
                  />
                )
              }
            />
            {selectedAmount ? (
              <ButtonJack
                children={t("Delete selected")}
                onClick={() => handleSubmit("open_modal_delete")}
              />
            ) : (
              <ButtonJack
                isLoading={loading}
                // disabled={isStillThereIncomplete}
                children={t("Validate")}
                style={{ minWidth: 60 }}
                onClick={() => handleSubmit("validate")}
                rightIcon={
                  <JackIcons
                    style={{ width: 20, height: 20 }}
                    name="arrow-forward"
                    // fill={
                    //   isStillThereIncomplete ? jackColors.neutral600 : "black"
                    // }
                    fill="black"
                  />
                }
              />
            )}
            {/* <OutlineButton
              style={{ height: 40, padding: "0px 12px" }}
              onClick={() => handleSubmit("draft")}
              isLoading={loading}
              disabled={loading}
            >
              Save as Draft
            </OutlineButton>
            <CustomButton
              style={{ height: 40, padding: "0px 12px" }}
              disabledGrey={isStillThereIncomplete}
              onClick={() => handleSubmit("validate")}
              isLoading={loading}
            >
              {wordingButton} <img src={caretRightIcon} />
            </CustomButton> */}
          </div>
        </div>
      </div>
      {/* <div
        className="d-flex"
        style={{
          position: "absolute",
          backgroundColor: "#DA649F",
          boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
          borderRadius: 32,
          gap: 8,
          padding: "8px 12px",
          bottom: bottomDeciderOtherFields(),
          left: "45%",
          zIndex: 9,
          cursor: "pointer",
        }}
        onClick={() => onClickOtherFields((p) => !p)}
      >
        {!isFirstScreenTable && <img src={ImgIcon} />}
        <GothamRegular className="font12" style={{ color: "white" }}>
          Other fields
        </GothamRegular>
        {isFirstScreenTable && <img src={ImgIcon} />}
      </div> */}
    </>
  );
};

export const PlaceholderLocalTable = (name, tLocalTransfer = () => {}) => {
  const { isName, isEmail, isAmount, isAccNumber, isBank, isNotes } =
    useBooleanTableLocal({
      name,
    });

  const placeholderDecider = () => {
    if (isName) return "Enter recipient name";
    if (isBank) return "Select bank";
    if (isAccNumber) return "Enter account number";
    if (isNotes) return "Enter transfer note";
    if (isEmail) return "Enter email";
    if (isAmount) return "IDR 0";
    return "-";
  };

  const placeholder = placeholderDecider();
  return (
    <span style={{ color: jackColors.neutral600 }}>
      {tLocalTransfer(placeholder)}
    </span>
  );
};

export const ModalEditTitle = ({
  close,
  value,
  containerStyle,
  onSubmit,
  maxLength = 50,
}) => {
  const [tempValue, setTempValue] = useState(value || "");
  const [showError, setShowError] = useState(false);
  const { t } = useTranslation("payroll/create");

  const ref = useRef(null);
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  useClickOutside({ ref, clickOutside: () => setTimeout(close, 20) });

  const { isDisabled } = ValidationValueContainWhiteSpace(tempValue);
  const errorCondition = isDisabled;
  const isMoreThanMaxLength = tempValue?.length > maxLength;
  const isError = showError && errorCondition;

  const errorGenerator = ({ value }) => {
    if (!value) return t("Please fill out this field");
    if (value?.length > maxLength) return `Max. ${maxLength} character reached`;
  };
  const errorMsg = errorGenerator({ value: tempValue });

  const handleOnChange = (value) => {
    const { valueValidation, isDisabled } =
      ValidationValueContainWhiteSpace(value);
    if (valueValidation.length > maxLength) return;
    if (!!valueValidation && !isDisabled) setShowError(false);
    if (isDisabled) setShowError(true);
    setTempValue(valueValidation);
  };

  const handleCancel = () => setTimeout(close, 20);
  const handleSubmit = () => {
    if (errorCondition || isMoreThanMaxLength) return;
    onSubmit(tempValue);
    setTimeout(close, 20);
  };

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        backgroundColor: "white",
        top: -17,
        left: -2,
        minWidth: 265,
        padding: showError ? "18px 8px 26px 8px" : "18px 8px",
        boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
        borderRadius: 4,
        zIndex: 10,
        ...containerStyle,
      }}
      className="d-flex align-items-center"
    >
      <MinimalistTextField
        label={""}
        error={isError}
        errorMsg={errorMsg}
        // name={name}
        // required={isRequired && "*"}
        value={tempValue}
        setValue={handleOnChange}
        onSubmit={handleSubmit}
        style={{ width: 272 }}
        autoFocus={true}
      />
      <div className="d-flex ml-2" style={{ gap: 8 }}>
        <JackIcons
          name="checkmark-circle-2"
          fill={jackColors.greenB9}
          style={{ cursor: "pointer" }}
          onClick={handleSubmit}
        />
        <JackIcons
          name="close-circle"
          fill={jackColors.neutral900}
          style={{ cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
    </div>
  );
};
