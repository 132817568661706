//import NextImage from "components/NextImage";
//import styles from "./styles.module.css";
//import SimpleCenteredModal from "modals/SimpleCenteredModal";
//import { GothamMedium, GothamRegular } from "components/Text";
//import DanamonTeaserModal from "modals/DanamonTeaserModal";
//import { useModalHook } from "components/Modals";

const DanamonAdvertisementModal = (
  {
    //isOpen = false,
    //toggle = () => {},
    //onClickLater = () => {},
  }
) => {
  return null;
  //const {
  //  isOpen: isOpenTeaserModal,
  //  open: openTeaserModal,
  //  close: closeTeaserModal,
  //} = useModalHook();
  //
  //const handleCloseTeaser = () => {
  //  closeTeaserModal();
  //  toggle();
  //};
  //
  //return (
  //  <SimpleCenteredModal
  //    woLineDividers
  //    isTransparentCloseBtn
  //    isOpen={isOpen}
  //    modalClassName={styles.danamonAdvertisementModal}
  //    headerStyle={{ padding: "0px", height: "40px" }}
  //    footerStyle={{ height: "100px" }}
  //    customButtons={
  //      <div className={styles.buttonsContainer}>
  //        <button
  //          type="button"
  //          className={styles.buttonOutline}
  //          onClick={onClickLater}
  //        >
  //          <GothamMedium style={{ color: "white" }}>Maybe later</GothamMedium>
  //        </button>
  //        <button
  //          type="button"
  //          className={styles.buttonFilled}
  //          onClick={openTeaserModal}
  //        >
  //          <GothamMedium style={{ color: "white" }}>
  //            See how it works
  //          </GothamMedium>
  //        </button>
  //      </div>
  //    }
  //    toggle={toggle}
  //  >
  //    <div className={styles.contentContainer}>
  //      <NextImage
  //        src="/images/danamon-advertisement-modal-image-1.png"
  //        width={333}
  //        height={190}
  //      />
  //      <div className={styles.textContainer}>
  //        <GothamMedium
  //          className="font20"
  //          style={{ color: "white", textAlign: "center" }}
  //        >
  //          Next level finance with our new <br />
  //          Checking Account
  //        </GothamMedium>
  //        <GothamRegular
  //          woFontColor
  //          className="font12"
  //          style={{ color: "white", textAlign: "center", marginTop: "8px" }}
  //        >
  //          Unlock a fully upgraded transaction experience for your business.
  //          Our advanced checking account centralizes your funds, eliminates
  //          fees, and ensures secure payments.
  //        </GothamRegular>
  //      </div>
  //    </div>
  //
  //    <DanamonTeaserModal
  //      isOpen={isOpenTeaserModal}
  //      toggle={handleCloseTeaser}
  //    />
  //  </SimpleCenteredModal>
  //);
};

export default DanamonAdvertisementModal;
