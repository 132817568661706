import React from "react";
import { ConfirmationModalJack } from "../../../components/ButtonsJack/confirmation";
import { useRouter } from "next/router";
import imgBack from "../../../assets/images/back-previous-page.svg";
import checkExcelImg from "../../../assets/images/check-excel.svg";
import failCreateImg from "../../../assets/images/fail-create-from-bg.svg";
import { apiBusiness } from "../../../tools/api";
import { Trans, useTranslation } from "react-i18next";

export const ModalConfirmationCloseLocal = ({
  toggle,
  isOpen,
  product = "local",
  isAfterDraft = false,
  customText,
  batchId,
}) => {
  const { query, push } = useRouter();
  const { t } = useTranslation("international/create");
  const { t: tPayroll } = useTranslation("payroll/create");
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  const isResubmit = query?.action === "resubmit";

  const isLocal = product === "local";
  const isPayroll = product === "payroll";
  const isInternational = product === "international";

  const textDecider = () => {
    if (customText) return customText;
    if (isAfterDraft) {
      return (
        <>
          <Trans
            i18nKey={tPayroll("dynamic.style_text_4")}
            components={[<span style={{ fontFamily: "GothamMedium" }} />]}
          />
        </>
      );
    }
    return isLocal
      ? tLocalTransfer(
          "Going back means starting over and losing all progress made."
        )
      : t("Going back means starting over and losing all progress made.");
  };

  const titleDecider = () => {
    if (isLocal) return tLocalTransfer("Close Local Transfer?");
    if (isPayroll) return tPayroll("Close Payroll?");
    return t("Close International Transfer?");
  };

  const NavigationPush = () => {
    if (isResubmit) return "/dashboard";
    if (isPayroll || isLocal) {
      if (isAfterDraft)
        return `/draft/?menu=${isPayroll ? "payroll" : "local_transfer"}`;
      return "/dashboard";
    }
    return "/dashboard";
  };

  const nav = NavigationPush();
  const title = titleDecider();
  const text = textDecider();

  const handleClick = async () => {
    if (isResubmit) {
      if (isLocal) {
        await apiBusiness.delete(`/local_transaction_batches/${batchId}`);
      }

      if (isPayroll) {
        await apiBusiness.delete(`/payrolls/${batchId}`);
      }
    }

    push(nav);
  };

  return (
    <ConfirmationModalJack
      onClick={handleClick}
      modal={isOpen}
      toggle={toggle}
      title={title}
      text={text}
      buttonTextRight={t("Close")}
      buttonTextLeft={t("Cancel")}
      img={imgBack}
      customContentClassName="modal-border-radius4"
    />
  );
};

export const ModalFailExcel = ({
  isOpen,
  toggle,
  text = "There are still some incorrect fields that don't meet the requirements. Please recheck and try uploading it again. ",
  img = checkExcelImg,
  isOnlyOneButton = true,
  buttonTextLeft = "Got it",
  buttonTextRight,
  onClickRight,
}) => {
  const { t } = useTranslation("payroll/create");
  return (
    <ConfirmationModalJack
      title={
        <span style={{ fontSize: 20, lineHeight: "24px" }}>
          {t("Let’s double-check your CSV file")}
        </span>
      }
      modal={isOpen}
      img={img}
      toggle={toggle}
      onClick={toggle}
      isOnlyOneButton={isOnlyOneButton}
      buttonTextRight={buttonTextRight}
      buttonTextLeft={buttonTextLeft}
      text={text}
      customContentClassName="modal-border-radius4"
      onClickRight={onClickRight}
    />
  );
};
export const ModalFailCreateBG = ({
  isOpen,
  toggle,
  isPayroll = false,
  backToFirst,
  customText = false,
}) => {
  const { push } = useRouter();

  const onClickLeft = () => {
    if (isPayroll) return push("/draft/?menu=payroll");
    return push("/draft/?menu=local_transfer");
  };

  return (
    <ConfirmationModalJack
      title={
        <span style={{ fontSize: 20, lineHeight: "24px" }}>
          Sorry, there’s a little problem on our system
        </span>
      }
      modal={isOpen}
      img={failCreateImg}
      toggle={toggle}
      buttonTextRight="Re-upload File"
      buttonTextLeft="Go to Draft"
      onClick={backToFirst}
      onClickLeft={onClickLeft}
      text={
        <>
          Some of the recipient details failed to be imported. You can review
          and submit the ones that were successfully imported in{" "}
          <span style={{ fontFamily: "GothamMedium" }}>Draft</span> or re-upload
          the file.
        </>
      }
      customContentClassName="modal-border-radius4"
    />
  );
};
