import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useForm } from "@tools";
import { colors, jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { CustomButton } from "../../../components/Buttons";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { CustomTextField } from "../../../components/inputs";
import { SmallScreenWrapper } from "../../../components/ScreenWrappers";
import { GothamRegular } from "../../../components/Text";
import { useHeader } from "../../../contexts/Layout/parent";
import { WrapperJackCenter } from "../../../pageComponents/crossBorder/components";
import { ProgressBarComponent } from "../../crossBorderComponents";
import { SimpleTextAreaJack } from "../../../components/inputs/simpleTextArea";
import { ValidationValueContainWhiteSpace } from "../../payrollCreateComponents/logics";
import { stepArrayLocalFunc } from "./parent";
import { ProgressBarJack } from "../../../components/Stepper";
import { ModalConfirmationCloseLocal } from "./modal";
import { useModalHook } from "../../../components/Modals";
import CategorySelection from "../../categoryComponents/general/components/CategorySelection";
import { isEmpty } from "lodash";
import {
  useAvailableCategories,
  useCategoryFlag,
} from "../../categoryComponents/general/hooks";
import { useTranslation } from "react-i18next";
import { translateArray } from "pageComponents/payrollCreateComponents/first";

export const LocalTransferFirstForm = ({ onSubmit: onSubmitProps }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const useFormObj = useForm();
  const { setHeader } = useHeader();
  const { isOpen, toggle } = useModalHook();
  const { t } = useTranslation("local-transfer/local-transfer");

  useEffect(() => {
    setHeader({
      type: "close",
      onCloseProps: () => toggle(),
      middleComponent: (
        <ProgressBarJack
          arrayStep={translateArray({
            t,
            array: stepArrayLocalFunc(0),
            key: "step",
          })}
        />
      ),
    });
  }, []);

  const { hasCategoryList } = useAvailableCategories();
  const { isRequiredCategory } = useCategoryFlag();
  const isMissingRequiredCategory = Boolean(
    isRequiredCategory && !selectedCategory && hasCategoryList
  );

  const { watch, handleSubmit, setValue } = useFormObj;
  const watchName = watch("batch_name");
  const { valueValidation, isDisabled } =
    ValidationValueContainWhiteSpace(watchName);
  const isDisabledSubmit = isDisabled || isMissingRequiredCategory;

  const onSubmit = (values) => {
    const payload = { type: "first", values };

    if (!isEmpty(selectedCategory)) {
      payload.values = { ...values, category_id: selectedCategory.id };
    }

    onSubmitProps(payload);
  };
  useEffect(() => {
    setValue("batch_name", valueValidation);
  }, [watchName]);

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
  };

  return (
    <>
      <WrapperJackCenter title={t("Pick a name for your local batch transfer")}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SimpleTextAreaJack
            useFormObj={useFormObj}
            label={t("Batch Transfer Name")}
            name="batch_name"
            placeholder={t("E.g., Vendor Payment June 2023")}
            maxLength={50}
            style={{ height: 60 }}
            containerStyle={{ marginBottom: 26 }}
          />

          <CategorySelection
            selectedCategory={selectedCategory ?? {}}
            onSelect={handleSelectCategory}
          />

          <div className="d-flex justify-content-end">
            <ButtonJack
              buttonType="submit"
              disabled={isDisabledSubmit}
              style={{ minWidth: 80 }}
              rightIcon={
                <JackIcons
                  name="arrow-forward"
                  style={{ width: 20, height: 20 }}
                  fill={
                    isDisabledSubmit
                      ? jackColors.neutral600
                      : jackColors.neutral900
                  }
                />
              }
            >
              {t("Next ")}
            </ButtonJack>
          </div>
        </form>
      </WrapperJackCenter>
      <ModalConfirmationCloseLocal
        toggle={toggle}
        isOpen={isOpen}
        product="local"
      />
    </>
  );
};
