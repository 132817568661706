import React, { useEffect, useRef, useState } from "react";
import { colors } from "../../../../../../assets/colors";
import {
  CustomButton,
  OutlineButton,
} from "../../../../../../components/Buttons";
import { ButtonJack } from "../../../../../../components/ButtonsJack/parent";
import { useModalHook } from "../../../../../../components/Modals";
import { GothamRegular } from "../../../../../../components/Text";
import {
  removeDuplicates,
  stringCutter,
} from "../../../../../../components/tools";
import { AdvancedCustomTooltip } from "../../../../../../components/Tooltip";
import { useClickOutside } from "../../../../../../universalFunctions/useClickOutside";
import { InputChip, isEmailChecker } from "./chip";
import { useEscapeKeypress } from "../../../../../../universalFunctions/useEscapeKeypress";
import { useTranslation } from "react-i18next";

export const EmailInfoLocalTransfer = ({
  children,
  email,
  additional_emails,
  disabled,
}) => {
  const { t } = useTranslation("payroll/create");
  const arr = [
    email && { title: t("Recipient Email"), msg: email },
    additional_emails.length && {
      title: t("Additional Email"),
      msg: additional_emails.join(", "),
    },
  ].filter((item) => item);

  const specialDisabled =
    !additional_emails.length && email && !stringCutter(email).includes("...");

  return (
    <AdvancedCustomTooltip
      disable={disabled || !email || specialDisabled}
      component={
        <div>
          {arr.map((item, index) => {
            const isLast = index == arr.length - 1;
            const { msg, title } = item;
            return (
              <div
                key={index}
                className="d-flex align-items-start"
                style={{
                  flexDirection: "column",
                  marginBottom: isLast ? 0 : 16,
                  width: 304,
                }}
              >
                <GothamRegular
                  className="font10"
                  style={{ color: colors.grey90 }}
                >
                  {title}
                </GothamRegular>
                <GothamRegular
                  style={{
                    color: colors.grey33,
                    width: 304,
                    textAlign: "left",
                  }}
                >
                  {msg}
                </GothamRegular>
              </div>
            );
          })}
        </div>
      }
      width={320}
    >
      {children}
    </AdvancedCustomTooltip>
  );
};

export const EmailTextLocalTransfer = ({ email, additional_emails }) => {
  const { t } = useTranslation("payroll/create");
  return (
    <>
      {email}
      {!!additional_emails.length && (
        <span style={{ color: colors.greyBB }}>
          {" "}
          +{additional_emails.length} {t("more")}
        </span>
      )}
    </>
  );
};

const emailTempValueFormatter = (tempValues) => {
  const { additional_email, recipient_email } = tempValues;
  const { values = [], tempValue } = recipient_email || {};
  const { values: aValues = [], tempValue: aTempValue } =
    additional_email || {};

  const emails = [values.length ? values : tempValue, aValues, aTempValue]
    .flat(2)
    .filter((email) => isEmailChecker(email));
  const cleanEmails = removeDuplicates(emails);
  const email = cleanEmails.join(", ");

  const isNotEmail = !isEmailChecker(tempValue);
  const disabledAdditional = !values.length && !isEmailChecker(tempValue);

  const falseEmail = (email) => email && !isEmailChecker(email);

  const disabledCausedByAdditional = () => {
    const isAdditionalExist = aValues.length || aTempValue;

    if (!isAdditionalExist) return false;

    if (values.length) return false;
    if (tempValue) return falseEmail(tempValue);

    return true;
  };

  const disabledGrey =
    falseEmail(tempValue) ||
    falseEmail(aTempValue) ||
    disabledCausedByAdditional();

  return { email, disabledAdditional, isNotEmail, disabledGrey };
};

export const EmailInput = ({
  setValue = () => {},
  value,
  scrollHeight,
  headerHeight,
  index,
  trHeight = 63,
  // only call these in modal
  isLast,
  onlyModal = false,
  close: closeProps = () => {},
  // only call these in modal
}) => {
  const [email, ...additional_emails] = (value || "")
    .split(",")
    .map((email) => email.replace(" ", ""));
  const { isOpen, toggle, close: closeRaw, open } = useModalHook();
  const { t } = useTranslation("payroll/create");

  const close = () => {
    closeRaw();
    closeProps();
  };

  useEffect(() => {
    if (!onlyModal) return;
    open();
  }, [onlyModal]);

  const refModal = useRef(null);

  const [tempValues, setTempValues] = useState({});

  const {
    disabledAdditional,
    email: result,
    disabledGrey,
  } = emailTempValueFormatter(tempValues);

  const handleConfirm = () => {
    setValue(result);
    close();
  };

  const labelDecider = () => {
    if (!email)
      return (
        <GothamRegular
          style={{
            color: colors.greyBB,
            PointerEvents: "none",
            cursor: "pointer",
          }}
        >
          {/* Email */}
        </GothamRegular>
      );

    return (
      <GothamRegular>
        <EmailTextLocalTransfer
          additional_emails={additional_emails}
          email={stringCutter(email)}
        />
      </GothamRegular>
    );
  };

  const defaultSetTempValues = (obj) => {
    const { error, ...rest } = obj || {};

    if (error) return setTempValues((prev) => ({ ...prev, ...obj }));
    setTempValues((prev) => ({ ...prev, ...rest }));
  };

  const topHeight = index * trHeight + headerHeight - scrollHeight;

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) return setIsFirstRender(false);
    close();
  }, [scrollHeight]);

  const positionsTop = () => {
    if (onlyModal) {
      if (isLast) return;
      return { top: 56 };
    }
    if (isLast)
      return { top: topHeight - (refModal?.current?.clientHeight || 0) };
    return { top: topHeight };
  };

  const positionBottom = () => {
    if (onlyModal) {
      if (isLast) return { bottom: 0 };
      return;
    }
  };

  useClickOutside({
    ref: refModal,
    dependency: tempValues,
    clickOutside: () => handleConfirm(),
  });
  useEscapeKeypress({ ref: refModal, escapeTo: closeProps });

  return (
    <>
      {!onlyModal && (
        <EmailInfoLocalTransfer
          disabled={isOpen}
          additional_emails={additional_emails}
          email={email}
        >
          <div
            onClick={toggle}
            style={{
              height: "34px",
              marginTop: "8px",
              paddingTop: "4px",
              paddingBottom: "12px",
              borderBottom: "1px solid rgb(230, 230, 232)",
            }}
          >
            {labelDecider()}
          </div>
        </EmailInfoLocalTransfer>
      )}

      <div
        style={{
          visibility: isOpen ? "" : "hidden",
          opacity: isOpen ? 1 : 0,
          position: onlyModal ? "absolute" : "fixed",
          ...positionsTop(),
          ...positionBottom(),
          padding: "12px 8px",
          backgroundColor: "white",
          width: "416px",
          borderRadius: "4px",
          boxShadow: "0px 4px 32px rgba(17, 17, 17, 0.1)",
          flexDirection: "column",
          gap: "16px",
          transition: "opacity 0.4s linear",
          zIndex: 100,
        }}
        ref={refModal}
      >
        <InputChip
          name="recipient_email"
          type="email"
          isReset={!isOpen}
          listener={defaultSetTempValues}
          defaultValues={[email]}
          info={t("Send transaction updates to recipient’s email.")}
          isSingle
        />
        <InputChip
          name="additional_email"
          type="email"
          isReset={!isOpen}
          listener={defaultSetTempValues}
          defaultValues={additional_emails}
          info={t("You can also notify up to 5 more email adresses.")}
          limit={5}
          disabled={disabledAdditional}
        />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "12px",
          }}
        >
          <ButtonJack onClick={close} className="w-100" type="outline">
            {t("Cancel")}
          </ButtonJack>
          <ButtonJack onClick={handleConfirm} className="w-100">
            {t("Confirm")}
          </ButtonJack>
        </div>
      </div>
    </>
  );
};
