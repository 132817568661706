import { isEmpty } from "lodash";
import { push } from "mixpanel-browser";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { colors, jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { Avatar } from "../../../../components/Avatar";
import { ConfirmationModalJack } from "../../../../components/ButtonsJack/confirmation";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { useModalHook } from "../../../../components/Modals";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { formatCurrencyNoDecimal } from "../../../../components/tools";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { fetch, useMutation } from "../../../../tools/api";
import { eventsTracker } from "../../../../universalFunctions/events";
import {
  getTotalAmountSummary,
  idrStringFormatter,
  pluralize,
} from "../../../invoiceComponents/data/formatter";
import { confirmIcon } from "../../data/constant";
import { SmallIconJack } from "../../../../components/Status/JackStatusIcon";
import { CardListSuccess } from "../../../successComponents/internationalSingle";
import { useTranslation } from "react-i18next";
import { useLanguage } from "public/locales/translationFunctions";

export const ReimbursementSummaryPage = () => {
  const { push, query } = useRouter();
  const { btcid } = query;
  const { localTransferBanks } = useConstants();
  const { t } = useTranslation("reimbursement/reimbursement");
  const {
    data: summary,
    refetch,
    loading,
  } = fetch({
    url: `/reimbursements/${btcid}`,
    formatter: ({ data }) => {
      const { reimbursement_child, local_recipient_data, name } = data;
      return { reimbursement_child, bank: local_recipient_data, name };
    },
    defaultValue: {},
  });

  const handleBack = () => {
    push({
      pathname: "/reimbursement/create",
      query: { ...query, step: "cba" },
    });
  };

  const { mutation: submit, loading: loadingSubmit } = useMutation({
    url: `/reimbursements/${btcid}/submit_reimbursement`,
    afterSuccess: (res) => {
      const { data } = res;
      eventsTracker("reimbursement_finish_batch", data);
      push({
        pathname: "/success",
        query: { type: "reimbursement", ...query },
      });
    },
  });
  const { isOpen: openModal, toggle: toggleModal } = useModalHook();
  const onClickMoveToDraft = () => {
    eventsTracker("reimbursement_move_to_draft_from_confirmtosubmit_page");
    push("/dashboard");
  };

  useEffect(() => {
    if (btcid) refetch();
  }, [btcid]);
  return (
    <div style={{ backgroundColor: colors.whiteFC }}>
      <div
        style={{
          minHeight: "calc(100vh - 60px)", //60px is navbar's height
          height: "auto",
          backgroundColor: colors.whiteFC,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "438px", marginTop: "52px" }}>
          <GothamMedium className="font24 mb-2" style={{ color: "#000000" }}>
            {t("Confirm to Submit")}
          </GothamMedium>
          <GothamRegular style={{ color: jackColors.grey6C, marginBottom: 32 }}>
            {t(
              "Make sure you're giving us the right details. You can’t change anything after hit the submit button."
            )}
          </GothamRegular>
          <ReimbursementDetails
            isLoading={loading}
            data={summary}
            name={summary?.name}
            isSimple={true}
          />
          <div
            style={{
              height: 1,
              width: "100%",
              backgroundColor: "#E6E6E8",
              marginTop: 32,
              marginBottom: 32,
            }}
          />
          <RecipientDetails
            data={summary?.bank ?? {}}
            isLoading={loading}
            handleChange={handleBack}
            localTransferBanks={localTransferBanks}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 16,
              marginTop: 32,
            }}
          >
            <ButtonJack
              isLoading={loadingSubmit}
              onClick={() => {
                submit();
              }}
              style={{ width: "100%" }}
            >
              {t("Submit Request")}
            </ButtonJack>
            <ButtonJack
              style={{ width: "100%" }}
              type="outline"
              onClick={toggleModal}
            >
              {t("Move to Draft")}
            </ButtonJack>
            <ButtonJack
              onClick={handleBack}
              style={{
                width: "100%",
                backgroundColor: "transparent",
              }}
            >
              {t("Back")}
            </ButtonJack>
          </div>
          <ConfirmationModalJack
            modal={openModal}
            img={confirmIcon.draftIcon}
            buttonTextLeft={t("Back")}
            buttonTextRight={t("Move to Draft")}
            title={t("Move this request to draft?")}
            text={t(
              "You can always visit Draft to edit and submit this request."
            )}
            onClick={onClickMoveToDraft}
            toggle={toggleModal}
          />
        </div>
      </div>
    </div>
  );
};

const elipsisStyle = {
  width: "100%",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

export const LeftRight = ({
  left,
  right,
  isBold = false,
  isLoading,
  leftStyle,
  rightStyle,
  icon,
  subTitle,
  separator = false,
  isLeftBold = false,
  iconColor = "#BBBBC0",
  productIcon,
}) => {
  if (separator)
    return (
      <div
        style={{ width: "100%", height: 1, backgroundColor: jackColors.greyE6 }}
      />
    );

  const Text = isBold ? GothamMedium : GothamRegular;
  const TitleText = isLeftBold ? GothamMedium : GothamRegular;
  return (
    <div className="w-100 d-flex justify-content-between">
      <div
        style={{ display: "flex", gap: 8, alignItems: "start", width: "50%" }}
      >
        {icon && (
          <JackIcons
            name={icon}
            style={{ height: 20, width: 20 }}
            fill={iconColor}
          />
        )}
        {productIcon && <SmallIconJack state={productIcon} />}
        <div style={{ width: "100%" }}>
          <TitleText
            style={{ ...elipsisStyle, color: jackColors.black34, ...leftStyle }}
          >
            {left}
          </TitleText>
          {subTitle && (
            <GothamRegular style={{ color: jackColors.grey90, fontSize: 10 }}>
              {subTitle}
            </GothamRegular>
          )}
        </div>
      </div>
      <div style={{ width: "50%", textAlign: "end" }}>
        {isLoading ? (
          <Skeleton height={20} width={120} />
        ) : (
          <Text
            style={{
              color: jackColors.black34,
              width: "100%",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",

              ...rightStyle,
            }}
          >
            {right}
          </Text>
        )}
      </div>
    </div>
  );
};

export const createReimbursementSummary = (
  reimbursementData,
  isSimple = true,
  isReleaser = false,
  isRetry = false
) => {
  const {
    reimbursement_child: data,
    amount,
    state,
    initial_amount,
  } = reimbursementData;
  const { t } = useTranslation("reimbursement/reimbursement");
  const { isBahasa: isID } = useLanguage();

  const dataLength = `${data?.length} ${
    isID ? "pengeluaran" : pluralize(data?.length, "Expense")
  }`;

  const isBatchCancelled = state == "cancelled";
  const noCancelledReceipts = data?.filter(
    ({ activity_state }) => activity_state != "cancelled"
  );

  const formattedExpandedData = isBatchCancelled
    ? []
    : data?.map((receipt) => {
        const { activity_state, ...rest } = receipt;
        const isRejected = Boolean(receipt?.rejected_by_id);
        const isCancelled = activity_state == "cancelled";
        const { users } = useConstants();
        if (isCancelled) {
          const userCancel = users?.find(
            ({ id }) => id == receipt?.user_id
          )?.name;
          const userReject = users?.find(
            ({ id }) => id == receipt?.rejected_by_id
          )?.name;
          const action = isRejected ? "Rejected" : "Canceled";
          const user = isRejected ? userReject : userCancel;
          return {
            subTitle: `(${action} by ${user})`,
            leftStyle: { textDecoration: "line-through" },
            rightStyle: { textDecoration: "line-through" },
            ...rest,
          };
        }

        return receipt;
      });

  const { totalAmountString, totalAmount } = getTotalAmountSummary(
    noCancelledReceipts ?? [],
    "total_amount"
  );

  const batchTotalAmount = isBatchCancelled
    ? idrStringFormatter(initial_amount)
    : totalAmountString;
  const titleStyle = { color: jackColors.grey90, fontSize: 12 };
  const font12 = { fontSize: 12 };
  const separator = {
    separator: true,
  };

  const { totalAmount: totalFee, totalAmountString: totalFeeIDR } =
    getTotalAmountSummary(noCancelledReceipts, "fee");

  const totalReceipts = noCancelledReceipts?.length;
  const fee = noCancelledReceipts?.[0]?.fee || 0;
  const handlingFee = {
    title: "Handilng fee",
    value: totalFeeIDR,
    subTitle: `(${totalReceipts} x ${idrStringFormatter(fee)} )`,
    titleStyle,
    valueStyle: font12,
    icon: "plus_circle",
  };

  const defaultArray = [
    {
      title: t("Number of Expense"),
      value: dataLength,
      titleStyle,
      valueStyle: font12,
    },
    {
      title: t("Total Amount"),
      value: batchTotalAmount,
      isExpand: true,
      expandData: formattedExpandedData,
    },
  ];

  const finalAmount = {
    title: isReleaser ? "Total payment" : "Final amount",
    value: idrStringFormatter(totalAmount + totalFee),
    isBold: true,
    titleStyle: font12,
    valueStyle: font12,
  };

  if (isSimple) return defaultArray;
  if (isRetry) return [...defaultArray, handlingFee];
  return [...defaultArray, handlingFee, separator, finalAmount];
};

const array = [
  {
    title: "Number of expense",
    value: "",
  },
  {
    title: "Total amount",
    value: "idr 12000",
    expenses: [],
  },
];

export const ReimbursementDetails = ({
  isLoading = false,
  data = [],
  fee = 0,
  woHeader = false,
  isSimple = true,
  isReleaser = false,
  isRetry = false,
  dataAfterRelease = false,
}) => {
  const { name } = data;
  const detailArray = createReimbursementSummary(
    data,
    isSimple,
    isReleaser,
    isRetry
  );

  const isAfterRelease = !!dataAfterRelease?.length;

  if (isAfterRelease)
    return (
      <CardListSuccess
        isAfterRelease={isAfterRelease}
        woHr
        headerObj={{
          title: "Reimbursement",
          desc: name,
          jackIcons: "reimbursement",
        }}
        array={dataAfterRelease}
      />
    );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
        lineHeight: 1,
        border: "1px solid #e6e6e8",
        padding: 12,
        borderRadius: 4,
      }}
    >
      {!woHeader && (
        <>
          <div className="d-flex" style={{ gap: 8 }}>
            <Avatar
              mainIcon={
                <JackIcons
                  name="reimbursement"
                  style={{ width: 20, height: 20 }}
                  fill="#fff"
                />
              }
              size="medium"
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <GothamMedium
                className="font12"
                style={{ color: jackColors.black34 }}
              >
                Reimbursement
              </GothamMedium>
              {isLoading ? (
                <Skeleton
                  width={"40%"}
                  height={16}
                  style={{ marginBottom: 0, padding: 0 }}
                  className="mb-0 p-0"
                />
              ) : (
                <GothamRegular
                  className="font12"
                  style={{ color: jackColors.grey90 }}
                >
                  {name}
                </GothamRegular>
              )}
            </div>
          </div>

          <div
            style={{ height: 1, width: "100%", backgroundColor: "#e6e6e8" }}
          />
        </>
      )}
      {isReleaser && (
        <div className="d-flex" style={{ flexDirection: "column", gap: 4 }}>
          <GothamMedium
            className="font12"
            style={{ color: jackColors.black34 }}
          >
            Payment calculation
          </GothamMedium>

          <div
            style={{ height: 1, width: "100%", backgroundColor: "#e6e6e8" }}
          />
        </div>
      )}

      {detailArray?.map((detail) => {
        const {
          isExpand,
          title,
          value,
          expandData,
          titleStyle,
          valueStyle,
          icon,
          subTitle,
          separator,
          isBold,
        } = detail;

        const Row = isExpand ? LeftRightExpand : LeftRight;
        return (
          <Row
            left={title}
            right={value}
            icon={icon}
            subTitle={subTitle}
            leftStyle={titleStyle}
            rightStyle={valueStyle}
            expandData={expandData}
            separator={separator}
            isBold={isBold}
            isLoading={isLoading}
          />
        );
      })}

      {/* <LeftRight
        left="Number of expense"
        right={length}
        isLoading={isLoading}
        leftStyle={{ color: jackColors.grey90, fontSize: 12 }}
        rightStyle={{ fontSize: 12 }}
      /> */}
      {/* <LeftRightExpand data={summary} /> */}
      {/* <LeftRight
        left="Total amount"
        right={totalAmountString}
        isLoading={isLoading}
      /> */}
      {/* <LeftRight
        left="Handling fee"
        right={handlingFee}
        isLoading={isLoading}
      />
      <LeftRight
        left="Final amount"
        right={finalAmount}
        isLoading={isLoading}
      /> */}
    </div>
  );
};

export const LeftRightExpand = ({
  right,
  left,
  expandData,
  openOnFirstRender = false,
}) => {
  const { isOpen, toggle } = useModalHook(openOnFirstRender);
  const dataEmpty = isEmpty(expandData);
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="d-flex" onClick={toggle} style={{ cursor: "pointer" }}>
          <GothamRegular
            className="font12"
            style={{ color: jackColors.grey90 }}
          >
            {left}
          </GothamRegular>
          {!dataEmpty && (
            <JackIcons
              name={isOpen ? "chevron_up" : "chevron_down"}
              fill={jackColors.grey90}
              style={{ width: 16, height: 16, marginLeft: 8 }}
            />
          )}
        </div>
        <GothamMedium className="font12">{right}</GothamMedium>
      </div>
      {isOpen && (
        <div style={{ paddingLeft: 6 }}>
          <div
            style={{
              borderLeft: "4px solid #e6e6e8",
              paddingLeft: 10,
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            {expandData?.map(
              ({
                merchant,
                total_amount,
                leftStyle,
                rightStyle,
                subTitle,
                icon,
                iconColor,
                productIcon,
              }) => {
                const textStyle = {
                  color: jackColors.grey90,
                  fontSize: 12,
                };
                return (
                  <LeftRight
                    left={merchant}
                    right={idrStringFormatter(total_amount)}
                    leftStyle={{ ...textStyle, ...leftStyle }}
                    rightStyle={{ ...rightStyle, ...textStyle }}
                    subTitle={subTitle}
                    icon={icon}
                    iconColor={iconColor}
                    productIcon={productIcon}
                  />
                );
              }
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const VerticalText = ({ title, text, isLoading }) => {
  return (
    <div
      style={{
        minHeight: 44,
        height: "auto",
        width: 203,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <GothamRegular style={{ color: jackColors.grey6C, marginBottom: 4 }}>
        {title}
      </GothamRegular>
      {isLoading ? (
        <Skeleton height={20} width={"60%"} />
      ) : (
        <GothamRegular style={{ color: jackColors.black34 }}>
          {text}
        </GothamRegular>
      )}
    </div>
  );
};

const leftGreyStyle = { color: "#909098" };

const RecipientDetails = ({
  data,
  isLoading,
  handleChange,
  localTransferBanks,
}) => {
  const { account_number, registered_name, payer_id } = data;
  const { t } = useTranslation("reimbursement/reimbursement");

  const getBankAlias = (payer_id, localTransferBanks) => {
    if (!payer_id) return "";
    if (!localTransferBanks) return "";

    const [bank] = localTransferBanks?.filter(({ id }) => id == payer_id);
    const { alias, label } = bank ?? {};

    return (alias || label) ?? "";
  };
  const bankAlias = getBankAlias(payer_id, localTransferBanks);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 12,
        border: "1px solid #e6e6e8",
        borderRadius: 4,
        gap: 16,
      }}
    >
      <div className="d-flex align-items-center" style={{ gap: 8 }}>
        <Avatar
          size="medium"
          mainIcon={
            <JackIcons
              name="person_outline"
              style={{ width: 20, height: 20 }}
              fill="#fff"
            />
          }
        />
        <GothamMedium className="font16" style={{ color: jackColors.black34 }}>
          {t("Recipient Details")}
        </GothamMedium>
        <ButtonJack
          isBullet={true}
          isSmall={true}
          classNameText="font12"
          onClick={handleChange}
        >
          {t("Change")}
        </ButtonJack>
      </div>
      <div style={{ height: 1, width: "100%", backgroundColor: "#e6e6e8" }} />
      <LeftRight
        left={t("Name")}
        right={registered_name}
        isLoading={isLoading}
        leftStyle={leftGreyStyle}
      />
      <LeftRight
        left={t("Bank")}
        right={bankAlias}
        isLoading={isLoading}
        leftStyle={leftGreyStyle}
      />
      <LeftRight
        left={t("Account number")}
        right={account_number}
        isLoading={isLoading}
        leftStyle={leftGreyStyle}
      />
    </div>
  );
};
