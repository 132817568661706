import { useEffect, useMemo, useState } from "react";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { getDefaultValuesArray } from "../localTransfer/formatter";
import { useTranslation } from "react-i18next";

// listener tools
// ini obeng, paku, palu, gergaji buat bikin listeners
const getObj = (arr, key) => arr.filter(({ name }) => name == key)[0]?.value;

// Array of array to Array of object
export const validationToArrayOfObject = (defaultArr) =>
  (defaultArr || []).map((arr) => {
    let obj = {};
    arr.forEach((item) => {
      const { name, value } = item;
      obj[name] = value;
    });
    return obj;
  });

const listenerFunc = (array) =>
  String(
    validationToArrayOfObject(array).map(
      ({ name, bank, account_number }) => `${name}${bank}${account_number}`
    )
  );

const errorLifter = (currArray, changedIds) =>
  currArray.map((array) => {
    const id = getObj(array, "id");

    const isIncludes = changedIds.includes(id);
    if (!isIncludes) return array;

    return array.map((item) => {
      const { error, invalidYellow, invalid, ...rest } = item;
      return rest;
    });
  });

const changedObj = (defaultValues, id) => {
  const defaultVal = validationToArrayOfObject(defaultValues);

  const obj =
    defaultVal.filter(({ id: defaultId }) => id == defaultId)[0] || {};

  return obj;
};

// listener tools

const errorListeners = ({
  mismatch,
  invalid,
  mismatchdArr,
  setMismatchdArr,
  invalidArr,
  setInvalidArr,
}) => {
  // WHY WE NEED TO USE LISTENER?
  // coz we need to update it forcefully to erase the error from our useState

  const mismatchListener = listenerFunc(mismatchdArr);

  // the name of the game: dapetin changedIds, lempar ke errorLifter
  useEffect(() => {
    if (!mismatchdArr.length) return;

    setMismatchdArr((currArray) => {
      const currArrOfObj = validationToArrayOfObject(currArray);

      const changedIds = currArrOfObj
        .map(({ name, id }) => {
          const { name: defaultName } = changedObj(mismatch, id);
          // bedanya disini doang
          if (defaultName != name) return id;

          return null;
        })
        .filter((item) => item);

      const result = errorLifter(currArray, changedIds);

      return result;
    });
  }, [mismatchListener]);

  const invalidListener = listenerFunc(invalidArr);

  useEffect(() => {
    if (!invalidArr.length) return;

    setInvalidArr((currArray) => {
      const currArrOfObj = validationToArrayOfObject(currArray);

      const changedIds = currArrOfObj
        .map(({ id, bank, account_number }) => {
          const { bank: defaultBank, account_number: defaultAccNumber } =
            changedObj(invalid, id);

          // bedanya disini doang
          if (bank != defaultBank || account_number != defaultAccNumber)
            return id;
        })
        .filter((item) => item);

      const result = errorLifter(currArray, changedIds);

      return result;
    });
  }, [invalidListener]);
};

const changedValuesFunc = (defaultValuesArr, array) => {
  const mapToValues = (arrayOfFields) =>
    String(arrayOfFields.map(({ value }) => value));

  const defaultValues = defaultValuesArr.map(mapToValues);

  const changedMismatch = array.filter((array) => {
    const stringValues = mapToValues(array);
    const isChanged = !defaultValues.includes(stringValues);
    return isChanged;
  });

  return changedMismatch;
};

export const useMismatchOrInvalid = (defaultValues) => {
  const array = useMemo(() => getDefaultValuesArray(defaultValues), []);
  const { t } = useTranslation("payroll/create");

  const { localTransferBanks } = useConstants();

  const toTable = (array, isInvalid) =>
    array.map((item) => {
      const {
        name_check_result,
        name,
        bank,
        account_number,
        local_recipient_id,
        id,
      } = item || {};

      const mismatchObj = !isInvalid
        ? {
            error: {
              msg: t(
                "Recipient name and bank account name doesn't match. You may edit or ignore this."
              ),
              title: " ",
            },
            invalidYellow: true,
          }
        : {};

      const invalidObj = isInvalid
        ? {
            error: {
              msg: t(
                "Bank account not found. If you choose to ignore, the funds wouldn’t be delivered."
              ),
              title: " ",
            },
            invalid: true,
          }
        : {};

      return [
        { name: "number" },
        { name: "name", value: name, ...mismatchObj },
        { name: "name_check_result", value: name_check_result, type: "text" },
        {
          name: "bank",
          value: bank,
          options: localTransferBanks,
          ...invalidObj,
        },
        { name: "account_number", value: account_number, ...invalidObj },
        { name: "action" },
        { name: "id", value: id },
        { name: "local_recipient_id", value: local_recipient_id },
      ];
    });

  const mismatch =
    toTable(
      array.filter(({ is_valid, is_match_name }) => !is_match_name && is_valid)
    ) || [];

  const invalid =
    toTable(
      array.filter(({ is_valid }) => !is_valid),
      true
    ) || [];

  const [mismatchdArr, setMismatchdArr] = useState(mismatch || []);

  const [invalidArr, setInvalidArr] = useState(invalid || []);

  const changedMismatch = changedValuesFunc(mismatch, mismatchdArr);

  const changedInvalid = changedValuesFunc(invalid, invalidArr);

  const defaultValuesArr = [...mismatch, ...invalid];

  const mergedValuesArr = [...mismatchdArr, ...invalidArr];

  const changedValues = [...changedMismatch, ...changedInvalid];

  const deletedIds = validationToArrayOfObject(defaultValuesArr)
    .filter((item) => {
      const { id } = item || {};
      const isExist = validationToArrayOfObject(mergedValuesArr).filter(
        ({ id: currId }) => id == currId
      ).length;
      return !isExist;
    })
    .map(({ id }) => id);

  const values = {
    mismatch,
    invalid,
    defaultValues: defaultValuesArr,
    mismatchdArr,
    setMismatchdArr,
    invalidArr,
    setInvalidArr,
    changedValues,
    array,
    deletedIds,
    mergedValuesArr,
  };

  errorListeners(values);

  return values;
};
