import React, { useEffect, useState } from "react";
import { jackColors } from "../../../../assets/colors";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import {
  RightModal,
  useSmoothRightModal,
} from "../../../../components/Modals/RightModal/parent";
import { BackButton, SpanMedium } from "../transferServiceModal/components";
import BannerSourceImg from "../../../../assets/jackImages/banner-source-payment.svg";
import { ConfirmationModalJack } from "../../../../components/ButtonsJack/confirmation";
import { TopUpModal, useModalHook } from "../../../../components/Modals";
import ImgSkipModal from "../../../../assets/images/ignore-validation.svg";
import ImgEmptyPocket from "../../../../assets/jackImages/empty-pocket.png";
import { useBooleanDanamonProduct, useBooleanMultipleProduct } from "./boolean";
import { formatCurrencyNoDecimal } from "../../../../components/tools";
import { useMyCompanyBalance } from "./logic";
import Skeleton from "react-loading-skeleton";
import { SmartActivityModal } from "../../parent";
import { Banner } from "../../../../components/Banner";
import { eventsTracker } from "../../../../universalFunctions/events";
import CustomTooltip from "../../../../components/Tooltip";
import AddFundsModal from "modals/smartActivityModal/jackDanamon/addFunds";
import {
  DANAMON_BALANCE_VALUE,
  MAIN_BALANCE_VALUE,
} from "pageComponents/jackTransferComponents/components/SourceOfFundsSelection";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";

export const useArraySourceOfPayment = ({
  data,
  detailData,
  propsTransferService,
  tLocalTransfer = (item) => item,
}) => {
  const { isUserJackUltimate } = propsTransferService || {};
  const {
    isDanamonFailedFetch,
    balanceDanamon,
    balancePocket,
    refetchBalance,
    loadingBalance,
    isNullBalanceDanamon,
    isNullBalancePocket,
  } = useMyCompanyBalance();

  // single release
  const { type } = data || {};
  const { includeWithdraw, includeCard } = useBooleanMultipleProduct(
    data || []
  );
  const { isInternationalTransfer, isWithdraw, isCard } =
    useBooleanDanamonProduct(type);

  const withdraw = isWithdraw || includeWithdraw;
  const card = isCard || includeCard;

  const { totalPaymentRaw } = detailData || {};

  const objDanamonAccount = {
    icon: "bank",
    title: tLocalTransfer("Primary Bank Account"),
    value: "danamon_balance",
    desc: (
      <Trans
        i18nKey={tLocalTransfer(
          +balanceDanamon < +totalPaymentRaw
            ? "dynamic.dynamic_text_16"
            : "dynamic.dynamic_text_14",
          {
            dupNum: formatCurrencyNoDecimal(+balanceDanamon),
          }
        )}
      />
    ),
    balance: +balanceDanamon,
    isNotEnoughBalance: +balanceDanamon < +totalPaymentRaw,
    isDanamonFailedFetch,
    isDisabled: !isUserJackUltimate || isNullBalanceDanamon || withdraw || card,
    isDanamonFailedFetch,
    refetchBalance: () => refetchBalance(),
    loading: loadingBalance,
    titleTooltip: isUserJackUltimate && (
      <CustomTooltip
        text={tLocalTransfer(
          "This is the available balance that you can use for transaction."
        )}
        popperClassName="white-arrow"
        style={{
          fontSize: "12px",
          lineHeight: "16px",
          backgroundColor: "white",
          color: jackColors.neutral800,
          maxWidth: 280,
          textAlign: "left",
        }}
      >
        <JackIcons
          name="info"
          fill={jackColors.neutral700}
          style={{ width: 14, height: 14, marginLeft: 4 }}
        />
      </CustomTooltip>
    ),
  };

  const objVaPocket = {
    icon: "account_balance_wallet",
    title: isUserJackUltimate
      ? tLocalTransfer("VA Pocket")
      : tLocalTransfer("Main Balance"),
    value: "indonesian_wallet",
    desc: (
      <Trans
        i18nKey={tLocalTransfer(
          +balancePocket < +totalPaymentRaw || isNullBalancePocket
            ? "dynamic.dynamic_text_16"
            : "dynamic.dynamic_text_14",
          {
            dupNum: formatCurrencyNoDecimal(+balancePocket),
          }
        )}
      />
    ),
    balance: +balancePocket,
    isNotEnoughBalance:
      +balancePocket < +totalPaymentRaw || isNullBalancePocket, //+totalPaymentRaw || isNullBalancePocket,
    loading: loadingBalance,
  };
  const objBankTransfer = {
    icon: "money",
    title: tLocalTransfer("Bank Transfer "), // with space
    value: "bank_transfer",
    desc: tLocalTransfer("Manual Verification"),
  };

  const arrayMainSource = [objDanamonAccount, objVaPocket];
  const arrayInternational = [...arrayMainSource, objBankTransfer];

  const array = isInternationalTransfer ? arrayInternational : arrayMainSource;

  return {
    array,
    objDanamonAccount,
    objVaPocket,
    loadingBalance,
    refetchBalance,
  };
};

export const SourceOfPaymentModal = ({
  data, // object (single) or array (batch)
  isOpen,
  array,
  loadingBalance: loading,
  toggle,
  totalAmount,
  isUserJackUltimate,
  activeTransferService,
  activeTransferBalance,
  setActiveTransferBalance,
  refetchBalance,
  setActiveService,
  isMoveBalance,
  t = (item) => item,
}) => {
  const [temporarySelected, setTemporarySelected] = useState({});
  const isVAPocket = temporarySelected?.value == "indonesian_wallet";
  const { type } = data || {};

  // Modal Change Balance
  const { toToggle, toListener } = useSmoothRightModal();
  const {
    isOpen: isOpenChangeBalanceToVaPocket,
    toggle: toggleChangeBalanceToVaPocket,
  } = useModalHook();
  const { isOpen: isOpenTopUpModal, toggle: toggleTopUpModal } = useModalHook();
  const { isOpen: isOpenTopUpDanamon, toggle: toggleTopUpModalDanamon } =
    useModalHook();
  const { isOpen: isOpenEmptyPocket, toggle: toggleEmptyPocket } =
    useModalHook();
  const { isOpen: isOpenAddFunds, toggle: toggleAddFunds } = useModalHook();

  const { includeWithdraw } = useBooleanMultipleProduct(data || []);
  const { isWithdraw, isCard, isLocalTransfer } =
    useBooleanDanamonProduct(type);

  const productDisabledPrimaryAccount = includeWithdraw || isCard || isWithdraw;

  const isDisabledPrimaryAccount =
    isUserJackUltimate && productDisabledPrimaryAccount;

  const isShowBannerinsufficient =
    isUserJackUltimate &&
    !loading &&
    array.some(({ balance }) => balance < totalAmount);
  const isAlreadyChooseService = !!activeTransferService.value;

  const handleActiveTransferBalance = (
    item,
    bypass = false,
    options = { isNoToggle: false }
  ) => {
    const { isNoToggle } = options ?? {};
    const { value, isNotEnoughBalance } = item || {};
    const isCollectionPocket = value == "indonesian_wallet";
    const isDanamonBalanceTemporary =
      temporarySelected?.value == "danamon_balance";

    setTemporarySelected(item);

    if (isNotEnoughBalance && !isMoveBalance) {
      eventsTracker("open_modal_low_balance_va_pocket");
      return toggleAddFunds();
    }

    if (
      isAlreadyChooseService &&
      isDanamonBalanceTemporary &&
      isCollectionPocket &&
      !bypass
    ) {
      return toggleChangeBalanceToVaPocket();
    }

    eventsTracker("choose_source_balance_release_payment");
    setActiveTransferBalance(item);
    if (isNoToggle) return;
    toToggle();
  };

  useEffect(() => {
    if (
      isUserJackUltimate &&
      data?.type === "balance_transfer" &&
      !!data?.source_account &&
      data?.source_account?.length > 0 &&
      array?.length > 0 &&
      array[0]?.balance > 0
    ) {
      if (data?.source_account === DANAMON_BALANCE_VALUE) {
        handleActiveTransferBalance(
          array?.find(({ value }) => value === "danamon_balance") ?? {},
          false,
          { isNoToggle: true }
        );
        return;
      }
      if (data?.source_account === MAIN_BALANCE_VALUE) {
        handleActiveTransferBalance(
          array?.find(({ value }) => value === "indonesian_wallet") ?? {},
          false,
          { isNoToggle: true }
        );
        return;
      }
    }
  }, [isUserJackUltimate, data?.type, array?.length, array?.[0]?.balance]);
  return (
    <>
      <RightModal
        isOpen={isOpen}
        toggle={() => {
          toggle();
        }}
        noPadding={true}
        width={[420]}
        listener={toListener}
        woClose
        array={[
          <div style={{ padding: 16 }}>
            <BackButton
              onClick={() => toToggle()}
              style={{ marginBottom: 8 }}
            />

            <GothamMedium className="font20" style={{ marginBottom: 32 }}>
              {isMoveBalance
                ? t("Select balance destination")
                : t("Select a payment method ")}
            </GothamMedium>
            {!loading &&
              (isShowBannerinsufficient || isDisabledPrimaryAccount) && (
                <CustomBannerBanking
                  msg={
                    isShowBannerinsufficient
                      ? t("Choose an account with enough balance to pay now")
                      : t(
                          "Primary Bank Account is not available for this transactions"
                        )
                  }
                />
              )}
            <div id="task-second">
              <CardSourceOfPayment
                array={array}
                isMoveBalance={isMoveBalance}
                isUserJackUltimate={isUserJackUltimate}
                activeTransferBalance={activeTransferBalance}
                setActiveTransferBalance={handleActiveTransferBalance}
                t={t}
              />
            </div>
          </div>,
        ]}
      />
      <TopUpModal
        modal={isOpenTopUpModal}
        toggle={toggleTopUpModal}
        dummyPaidButton
        paid
        isLocalTransfer={isLocalTransfer}
      />
      <AddFundsModal
        isOpen={isOpenAddFunds}
        toggle={() => {
          refetchBalance();
          toggleAddFunds();
        }}
        defaultVA={isVAPocket}
        t={t}
        isLocalTransfer={isLocalTransfer}
      />
      <ConfirmationModalJack
        modal={!isMoveBalance && isOpenEmptyPocket}
        toggle={toggleEmptyPocket}
        image={
          <img
            src={ImgEmptyPocket}
            style={{ width: "100%", marginBottom: 20 }}
          />
        }
        title="VA Pocket balance is insufficient"
        text="You can add funds by receiving transfers on any Jack Virtual Account you own."
        isOnlyOneButton
        buttonTextLeft="Ok, got it"
        woCloseButton
      />
      <SmartActivityModal
        isOpen={isOpenTopUpDanamon}
        toggle={toggleTopUpModalDanamon}
        data={{
          type: "jack-danamon",
        }}
      />
      <ConfirmationModalJack
        modal={isOpenChangeBalanceToVaPocket}
        toggle={toggleChangeBalanceToVaPocket}
        onClick={() => handleActiveTransferBalance(temporarySelected, true)}
        buttonTextRight="Yes, change"
        buttonTextLeft="Cancel"
        img={ImgSkipModal}
        text={t(
          "The selected transfer service won't apply and we'll process your transaction under Jack's system."
        )}
        title={t("Change to VA Pocket?")}
        customContentClassName="modal-border-radius4"
      />
    </>
  );
};

export const CardSourceOfPayment = ({
  array,
  isUserJackUltimate,
  activeTransferBalance,
  setActiveTransferBalance = () => {},
  isMoveBalance,
  t = (item) => item,
}) => {
  const [hoverValue, setHoverValue] = useState(false);
  return (
    <div>
      {array?.map((item, index) => {
        const isFirst = index === 0;
        const {
          title,
          value,
          icon,
          desc,
          isNotEnoughBalance,
          isDisabled,
          isDanamonFailedFetch,
          refetchBalance,
          loading,
          titleTooltip,
        } = item || {};
        const isDanamonAccount = value === "danamon_balance";
        const isCollectionPocket = value == "indonesian_wallet";
        const isActive = activeTransferBalance?.value === value;
        const isHover = hoverValue === value;

        const isSufficientBalance = !isNotEnoughBalance;
        const isInsufficientNonUltimate =
          !isUserJackUltimate && isDanamonAccount && isNotEnoughBalance;

        const bgColorDecider = () => {
          if (
            (isDisabled && !isMoveBalance) ||
            (isDisabled && isMoveBalance && isDanamonAccount)
          )
            return jackColors.neutral400;
          if (!isNotEnoughBalance && isHover) return jackColors.neutral400;
          return "white";
        };
        // const { t } = useTranslation("ledgers/ledgers");

        if (loading)
          return (
            <Skeleton key={index} width={"100%"} height={60} className="mt-3" />
          );

        return (
          <div
            key={index}
            onMouseEnter={() => setHoverValue(value)}
            onMouseLeave={() => setHoverValue(false)}
          >
            {isDanamonAccount && (
              <div
                style={{ position: "relative", marginBottom: -12, zIndex: 1 }}
              >
                <img src={BannerSourceImg} />
                <GothamMedium
                  className="font12"
                  style={{
                    position: "absolute",
                    top: "4px",
                    left: "16px",
                    color: "white",
                  }}
                >
                  {t("Enjoy free transfer fee for your transactions!")}
                </GothamMedium>
              </div>
            )}
            <div
              style={{
                border: `1px solid ${
                  isActive ? jackColors.neutral900 : jackColors.neutral500
                }`,
                borderRadius: 12,
                padding: 16,
                marginTop: !isFirst && 16,
                cursor: "pointer",
                backgroundColor: bgColorDecider(),
                position: "relative",
                zIndex: 2,
              }}
              className="d-flex align-items-center justify-content-between"
              onClick={() => {
                if (isMoveBalance) {
                  if (!isDanamonAccount) setActiveTransferBalance(item);
                } else {
                  !isNotEnoughBalance &&
                    !isDisabled &&
                    setActiveTransferBalance(item);
                }
              }}
            >
              <div className="d-flex align-items-center" style={{ gap: 12 }}>
                <div
                  style={{
                    padding: 6,
                    backgroundColor: jackColors.neutral400,
                    borderRadius: "50%",
                    width: 32,
                    height: 32,
                  }}
                >
                  <JackIcons
                    name={icon}
                    style={{ width: 20, height: 20 }}
                    fill={jackColors.neutral900}
                  />
                </div>
                <div>
                  <div className="d-flex">
                    <GothamMedium
                      className="font12 mb-1"
                      style={{ color: jackColors.neutral900 }}
                    >
                      {title}
                    </GothamMedium>
                    {titleTooltip && titleTooltip}
                  </div>
                  {isDanamonFailedFetch && isDanamonAccount ? (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        refetchBalance();
                      }}
                    >
                      <GothamRegular
                        className="font12"
                        style={{ color: jackColors.neutral700 }}
                      >
                        {t("Failed to update your balance")}{" "}
                        <SpanMedium
                          style={{
                            textDecoration: "underline",
                            color: jackColors.neutral900,
                          }}
                        >
                          {t("Refresh")}
                        </SpanMedium>
                      </GothamRegular>
                    </div>
                  ) : (
                    <GothamRegular
                      className="font12"
                      style={{
                        color:
                          isInsufficientNonUltimate && !isMoveBalance
                            ? jackColors.neutral700
                            : isNotEnoughBalance && !isMoveBalance
                            ? jackColors.redE7
                            : jackColors.neutral700,
                      }}
                    >
                      {desc}
                    </GothamRegular>
                  )}
                </div>
              </div>
              <div>
                {isMoveBalance ? (
                  <JackIcons
                    name={
                      isActive ? "checkmark-circle-2" : "checkbox-circle-empty"
                    }
                    fill={
                      isActive ? jackColors.neutral900 : jackColors.neutral500
                    }
                  />
                ) : (
                  <>
                    {isSufficientBalance || isDisabled ? (
                      <JackIcons
                        name={
                          isActive
                            ? "checkmark-circle-2"
                            : "checkbox-circle-empty"
                        }
                        fill={
                          isActive
                            ? jackColors.neutral900
                            : jackColors.neutral500
                        }
                      />
                    ) : (
                      <AddFundsButton
                        onClick={() => setActiveTransferBalance(item)}
                        t={t}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const CardPayment = ({
  title = "title",
  desc = false,
  emptyDesc = "",
  emptyIcon = "more-horizontal",
  icon: iconName = "bank",
  styleContainer,
  isWarningDesc = false,
  onClick = () => {},
  customBanner = false,
  isMoveBalance,
  id = "",
}) => {
  const isValueEmpty = !desc;
  const icon = isValueEmpty ? emptyIcon : iconName;

  return (
    <div style={{ position: "relative" }}>
      <div
        id={id}
        className="d-flex justify-content-between align-items-center"
        style={{
          position: "relative",
          zIndex: 5,
          padding: "12px 16px",
          border: `1px solid ${jackColors.neutral500}`,
          borderRadius: 8,
          cursor: "pointer",
          backgroundColor: "white",
          ...styleContainer,
        }}
        onClick={onClick}
      >
        <div className="d-flex align-items-center">
          <div
            style={{
              padding: 6,
              backgroundColor: jackColors.neutral400,
              borderRadius: "100%",
              width: 32,
              height: 32,
              marginRight: 8,
            }}
          >
            <JackIcons
              name={icon}
              fill={jackColors.neutral900}
              style={{ height: "100%", width: "100%" }}
            />
          </div>
          <div>
            <GothamMedium className="font12 mb-1">{title}</GothamMedium>
            <GothamRegular
              className="font12"
              style={{
                color:
                  isValueEmpty || (isWarningDesc && !isMoveBalance)
                    ? jackColors.redE7
                    : jackColors.neutral900,
              }}
            >
              {isValueEmpty ? emptyDesc : desc}
            </GothamRegular>
          </div>
        </div>
        {isValueEmpty ? (
          <JackIcons
            name="chevron-right-outline"
            fill={jackColors.neutral900}
            style={{ height: 20, width: 20 }}
          />
        ) : (
          <div
            style={{
              padding: 8,
              border: `1px solid ${jackColors.neutral500}`,
              borderRadius: 8,
              cursor: "pointer",
            }}
          >
            <JackIcons
              name="edit-outline"
              fill={jackColors.neutral900}
              style={{ height: 16, width: 16 }}
            />
          </div>
        )}
      </div>
      {!!customBanner && (
        <div
          style={{
            position: "relative",
            bottom: 0,
            left: 0,
            zIndex: 1,
            padding: "16px 16px 8px 16px",
            backgroundColor: jackColors.neutral400,
            width: "100%",
            borderRadius: "0px 0px 8px 8px",
            marginTop: -8,
          }}
        >
          {customBanner}
        </div>
      )}
    </div>
  );
};

export const AddFundsButton = ({ onClick, style, t }) => {
  return (
    <div
      style={{
        padding: 6,
        border: `1px solid ${jackColors.neutral500}`,
        borderRadius: 4,
        gap: 8,
        ...style,
      }}
      className="d-flex align-items-center hover-400"
      onClick={onClick}
    >
      <JackIcons
        name="plus-circle-outline"
        fill={jackColors.neutral900}
        style={{ width: 20, height: 20 }}
      />
      <GothamMedium>{t("Add Funds")}</GothamMedium>
    </div>
  );
};

const LoadingCardOfPayment = () => (
  <div>
    <Skeleton
      width={"100%"}
      height={60}
      className="mb-2"
      style={{ borderRadius: 8 }}
    />
    <Skeleton width={"100%"} height={60} style={{ borderRadius: 8 }} />
  </div>
);

export const CustomBannerBanking = ({
  msg = "message",
  type = "warning",
  title,
  style,
  ...props
}) => {
  return (
    <Banner
      title={title}
      msg={msg}
      type={type}
      style={{ borderRadius: 8, marginBottom: 32, ...style }}
      iconStyle={{ marginBottom: -4 }}
      {...props}
    />
  );
};
