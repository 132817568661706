import { colors } from "../assets/colors";
import { isEmpty } from "lodash";
import { GothamRegular } from "./Text";
import { snakeCase, titleCase } from "change-case";
import { JackIcons } from "../assets/jackIcons/parent";
import { windowDimension } from "./tools";
import { useTranslation } from "react-i18next";

export const StatusLabelJack = ({
  status,
  isOutline,
  customStatusColors = [],
  icon = null,
  woTextFormatter,
  customContainerStyle,
  color,
  style,
  woTitleCase = false,
  product,
}) => {
  const statusSnakeCase = snakeCase(status);
  const titleCasedStatus = woTitleCase ? status : titleCase(status);
  const { t } = useTranslation("status/status");

  const currentProduct = (() => {
    if (!product) return null;
    if (product === "payroll") return "payroll";
    // NOTE TO DEV: LANJUTIN DISINI UNTUK PRODUK LAINNYA
  })();

  //provide customStatusColors to override default colors
  const successArr = [
    "success",
    "completed",
    "paid",
    "active",
    "Ready to submit",
    "Mark as approved",
    "Approved",
    "complete",
  ];
  const isPartialFailedArr = ["partial_failed"];
  const draftArr = [
    "draft",
    "created",
    "drafted",
    "expired",
    "requested",
    "static_trx_expired",
  ];
  const inProgressArr = [
    "in-progress",
    "in_progress",
    "on_progress",
    "validating_payment",
    "validating payment",
    "Missing details",
    "need_update",
    "waiting_payment",
    "waiting_for_payment",
    "in_progress_disbursement",
    "waiting_for_confirmation",
  ];
  const pendingArr = [
    "pending",
    "pending_approval",
    "waiting_for_approval",
    "waiting_for_release",
    "waiting_for_payment",
  ];
  const failedArr = [
    "failed",
    "refunded",
    "awaiting_refund",
    "declined",
    "balance_return",
    "Mark as rejected",
    "Failed",
  ];
  const cancellerArr = [
    "cancelled",
    "canceled",
    "rejected",
    "rejected_by_admin",
    "rejected_by_approver",
    "approval_rejected",
    "Already exists",
    "Deactivated",
  ];
  const isScheduled = ["scheduled"];
  const paymentDelayedArr = ["payment_delayed"];
  const defaultStatusColors = [
    {
      statuses: successArr,
      color: colors.green06,
    },
    {
      statuses: draftArr,
      color: colors.neutral600,
    },
    {
      statuses: inProgressArr,
      color: colors.yellowFFC,
    },
    {
      statuses: pendingArr,
      color: colors.orangeFF,
    },
    {
      statuses: [...failedArr, ...cancellerArr],
      color: colors.redDC,
    },
    {
      statuses: isPartialFailedArr,
      color: colors.blue9a,
    },
    {
      statuses: isScheduled,
      color: colors.blue60,
    },
    {
      statuses: paymentDelayedArr,
      color: colors.orangeFF,
    },
  ];

  const statusColors = isEmpty(customStatusColors)
    ? defaultStatusColors
    : customStatusColors;

  const circleColor =
    color ||
    statusColors.find((statusColor) =>
      statusColor.statuses.includes(statusSnakeCase)
    )?.color ||
    statusColors.find((statusColor) => statusColor.statuses.includes(status))
      ?.color ||
    colors.neutral600;

  const textLabel =
    statusColors.find((statusColor) => statusColor.statuses.includes(status))
      ?.customTextLabel || titleCasedStatus;

  const containerStyle = {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    padding: "6px 8px",
    border: isOutline ? `1px solid ${colors.neutral500}` : "none",
    borderRadius: "4px",
    width: "fit-content",
    ...style,
    ...customContainerStyle,
  };

  const circleStyle = {
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: circleColor,
  };

  const textStyle = {
    color: colors.neutral900,
    // fontSize: "10px",
    // lineHeight: "12px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  };

  const isSuccess = successArr.includes(statusSnakeCase);
  const isDraft = draftArr.includes(statusSnakeCase);
  const isInProgress = inProgressArr.includes(statusSnakeCase);
  const isPending = pendingArr.includes(statusSnakeCase);
  const isFailed = failedArr.includes(statusSnakeCase);
  const isCancelled = cancellerArr.includes(statusSnakeCase);
  const isPaymentDelayed = paymentDelayedArr.includes(statusSnakeCase);
  const textDecider = (stat, product) => {
    if (woTextFormatter) return stat;
    if (isSuccess && product) return `success_${product}`;
    if (isSuccess) return "success";
    if (isInProgress) return "in progress";
    if (isPending) return "pending_approval";
    if (isFailed) return "failed";
    if (isCancelled) return "cancelled";
    if (isDraft) return "draft";
    if (isPaymentDelayed) return "Payment Delayed";
    return stat;
  };
  const { isTabOrPhone } = windowDimension();

  return (
    <div style={containerStyle}>
      {icon ? icon : <div style={circleStyle} />}
      <GothamRegular
        style={textStyle}
        className={isTabOrPhone ? "font12" : "font10"}
      >
        {t(
          woTextFormatter
            ? textLabel
            : titleCase(textDecider(status, currentProduct))
        )}
      </GothamRegular>
    </div>
  );
};

export const TagTeamLabel = ({
  teamName,
  teamColor = colors.neutral900,
  isOutline,
  style,
}) => {
  const containerStyle = {
    display: "flex",
    gap: "7px",
    alignItems: "center",
    padding: "4px 8px",
    border: isOutline ? `1px solid ${colors.neutral500}` : "none",
    borderRadius: "4px",
    width: "fit-content",
    ...style,
  };

  const iconStyle = {
    width: 12,
    height: 12,
  };

  const textStyle = {
    color: teamColor,
    lineHeight: "12px",
  };

  return (
    <div style={containerStyle}>
      {teamName ? (
        <>
          <JackIcons
            name="pricetags-outline"
            style={iconStyle}
            fill={teamColor}
          />
          <GothamRegular className="font10" style={textStyle}>
            {teamName}
          </GothamRegular>
        </>
      ) : (
        <GothamRegular className="font12" style={textStyle}>
          Unassigned
        </GothamRegular>
      )}
    </div>
  );
};
