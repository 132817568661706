import React, { useEffect, useRef, useState } from "react";
import { TextField } from "@material-ui/core";
import {
  useClickEnter,
  useClickOutside,
} from "../../../../universalFunctions/useClickOutside";
import cancelIcon from "../../../../assets/images/cancel-icon-grey.svg";
import checkIcon from "../../../../assets/images/check-green.svg";
import {
  MinimalistSelect,
  MinimalistTextField,
} from "../../../../components/inputs";
import { useBooleanTable } from "./logic";
import { titleCase } from "change-case";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { FormatterOptionsBank } from "../formatter";
import { ErrorMessageGenerator } from "../logic";
import {
  appyRegexSymbols,
  containsOnlyDigits,
  formatCurrencyNoDecimal,
} from "../../../../components/tools";
import {
  CheckerDataDuplicate,
  ErrorMessageGeneratorLocal,
  useBooleanTableLocal,
} from "../../../localTransferEnhancement/create/table/logic";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { jackColors } from "../../../../assets/colors";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { GothamMedium } from "../../../../components/Text";
import { useEscapeKeypress } from "../../../../universalFunctions/useEscapeKeypress";
import { useTranslation } from "react-i18next";

export const defaultOnChangeTablePayroll = ({
  setData,
  index,
  name,
  value,
  woInvalid,
}) =>
  setData((prev) => {
    const result = prev.map((array, childIndex) => {
      const isChangedRow = index == childIndex;

      if (!isChangedRow) return array;
      const newArray = array.map((item) => {
        const { name: currName } = item;
        if (currName != name) return item;
        return {
          ...item,
          value,
          invalid: !!value || woInvalid ? false : true,
        };
      });
      return newArray;
    });

    const dataProcessed = CheckerDataDuplicate({ data: result });
    return dataProcessed;
  });

export const ModalEditTable = ({
  data,
  item,
  setData,
  index,
  childIndex,
  isLocalTransfer,
  isNumber,
  close,
  notRequired,
  style,
  getWidth = 256,
  onEditConfirm = () => {},
}) => {
  const [showError, setShowError] = useState(false);
  const [tempValue, setTempValue] = useState(
    data[index][childIndex]?.value || ""
  );
  const { name, label } = item;
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  const { t } = useTranslation("payroll/create");
  const tDecider = isLocalTransfer ? tLocalTransfer : t;

  const { isAmount, isRequired: isRequiredLocal } = useBooleanTableLocal({
    name,
  });
  const {
    isSalary,
    isRequired: isRequiredPayroll,
    isAccNumber,
    isNotes,
  } = useBooleanTable({ name });
  const isRequired = isLocalTransfer ? isRequiredLocal : isRequiredPayroll;
  const ErrorMessageFunc = isLocalTransfer
    ? ErrorMessageGeneratorLocal
    : ErrorMessageGenerator;

  const {
    msg: errorMsg,
    isBelowMinimumAmount,
    isContainOnlyNumber,
  } = ErrorMessageFunc({
    name,
    value: tempValue,
    t: tDecider,
  });

  const isOnlyNumber = isAccNumber;
  const isCurrency = isAmount || isSalary;
  const isValueEmpty = !Boolean(tempValue);
  const excludeSymbol = isNotes;
  const isError =
    showError &&
    isRequired &&
    (isValueEmpty || isBelowMinimumAmount || isContainOnlyNumber);
  const isAlignRightModal = isAmount || isSalary;

  const ref = useRef(null);
  const buttonRef = useRef(null);

  const onChangeData = (value) =>
    defaultOnChangeTablePayroll({
      setData,
      index,
      name,
      value,
      woInvalid: notRequired,
    });

  const handleOnChange = (value) => {
    if (!Boolean(value)) setShowError(true);
    if (Boolean(value)) setShowError(false);

    const ValueDecider = () => {
      if (isCurrency) return formatCurrencyNoDecimal(value);
      if (excludeSymbol) return appyRegexSymbols(value);
      return value;
    };

    const newValue = ValueDecider();
    setTempValue(newValue);
  };
  const handleCancel = () => setTimeout(close, 20);
  const handleSubmit = () => {
    if (!notRequired) {
      if (!tempValue || isBelowMinimumAmount || isContainOnlyNumber) return;
      if (!tempValue) return;
    }

    onEditConfirm();
    onChangeData(tempValue);
    setTimeout(close, 20);
  };

  useEffect(() => {
    if ((isCurrency && isBelowMinimumAmount) || isContainOnlyNumber)
      return setShowError(true);
    if (isCurrency) {
      setTempValue(formatCurrencyNoDecimal(tempValue));
    }
  }, [tempValue]);

  const paddingDecider = () => {
    if (notRequired) return "18px 8px";
    if (showError) return "18px 8px 26px 8px";
    return "18px 8px";
  };

  useClickOutside({
    ref,
    dependency: tempValue,
    clickOutside: () => {
      if (isError || !tempValue || isBelowMinimumAmount) {
        handleCancel();
      } else {
        handleSubmit();
      }
    },
  });
  useEscapeKeypress({ ref, escapeTo: () => setTimeout(close, 20) });

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        backgroundColor: "white",
        top: -5,
        left: isAlignRightModal ? -60 : 0,
        width: getWidth,
        padding: paddingDecider(),
        boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
        borderRadius: 4,
        zIndex: 10,
        ...style,
      }}
      className="d-flex align-items-center"
    >
      <MinimalistTextField
        name={name}
        label={""}
        error={isError}
        errorMsg={errorMsg}
        isNumber={isOnlyNumber}
        prefix={isCurrency && "IDR"}
        // required={isRequired && "*"}
        value={tempValue}
        setValue={handleOnChange}
        onSubmit={handleSubmit}
        style={{ width: getWidth - 88 }}
        inputPropsStyle={{
          padding: "2px 0px 1px 0px",
          borderBottom: "1px solid #BBBBC0",
        }}
        maxLength={isNotes && 50}
        autoFocus={true}
      />
      <div className="d-flex" style={{ gap: 8, marginLeft: 16 }}>
        <JackIcons
          name="checkmark-circle-2"
          fill={jackColors.greenB9}
          style={{ cursor: "pointer", width: 24, height: 24 }}
          onClick={handleSubmit}
        />
        <JackIcons
          name="close-circle"
          fill={jackColors.neutral900}
          style={{ cursor: "pointer", width: 24, height: 24 }}
          onClick={handleCancel}
        />
      </div>
    </div>
  );
};

export const ModalEditSelectTable = ({
  data,
  item,
  setData,
  index,
  childIndex,
  close,
  options,
  style,
  getWidth = 200,
  required,
  onEditConfirm = () => {},
}) => {
  const [tempValue, setTempValue] = useState(
    data[index][childIndex]?.value || ""
  );

  const { name, label } = item;

  const defaultValue = {
    value: tempValue,
    label: tempValue,
  };

  const ref = useRef(null);

  useEffect(() => {
    if (!!tempValue) {
      return ref?.current?.focus();
    }
  }, [tempValue]);

  const { localTransferBanks: optionsBank } = useConstants();
  const formattedOptionsBank = FormatterOptionsBank({ options: optionsBank });
  const getIdBank = (valueRaw) =>
    formattedOptionsBank.filter(({ value }) => value == valueRaw)[0];

  const onChangeData = (value) => {
    const idBank = getIdBank(value);
    const { id } = idBank || {};

    setData((prev) => {
      const result = prev.map((array, childIndex) => {
        const isChangedRow = index == childIndex;
        if (!isChangedRow) return array;
        const newArray = array.map((item) => {
          const { name: currName } = item;
          if (currName != name) return item;
          return {
            ...item,
            id,
            details: idBank,
            value,
            invalid: Boolean(value) ? false : true,
          };
        });
        return newArray;
      });
      const dataProcessed = CheckerDataDuplicate({ data: result });
      return dataProcessed;
    });
  };

  const refButton = useRef(null);

  const handleOnChange = (e) => {
    setTempValue(e.value);
    refButton?.current?.focus();
  };

  const handleCancel = () => setTimeout(close, 20);
  const handleSubmit = () => {
    onEditConfirm();
    onChangeData(tempValue);
    setTimeout(close, 20);
  };

  useClickOutside({
    ref,
    dependency: tempValue,
    clickOutside: () => {
      if (!tempValue) {
        handleCancel();
      } else {
        handleSubmit();
      }
    },
    isOldMethod: true,
  });
  useEscapeKeypress({ ref, escapeTo: close });

  return (
    <div
      ref={ref}
      className="d-flex align-items-center"
      style={{
        position: "absolute",
        backgroundColor: "white",
        zIndex: 20,
        top: -5,
        left: 0,
        width: 265,
        boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
        backgroundColor: "white",
        borderRadius: 4,
        padding: "18px 8px",
        ...style,
      }}
    >
      <div style={{ width: getWidth }}>
        <MinimalistSelect
          placeholder=""
          options={options || formattedOptionsBank}
          onChange={handleOnChange}
          defaultValue={defaultValue}
          value={tempValue}
          // noArrowDropDown
          noAsterisk
          autoFocus={true}
          // required={required}
        />
      </div>
      <div className="d-flex ml-2" style={{ gap: 8, position: "relative" }}>
        <button
          ref={refButton}
          onKeyPress={handleSubmit}
          className="invisible-button"
        />
        <JackIcons
          name="checkmark-circle-2"
          fill={jackColors.greenB9}
          style={{ cursor: "pointer" }}
          onClick={handleSubmit}
        />
        <JackIcons
          name="close-circle"
          fill={jackColors.neutral900}
          style={{ cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
    </div>
  );
};

export const ModalEditTableCombination = ({
  data,
  item,
  setData,
  index,
  childIndex,
  close,
  style,
  dataCombination,
}) => {
  const [showError, setShowError] = useState(false);
  const [tempArr, setTempArr] = useState(dataCombination);
  const valueName = tempArr[0]?.value;
  const { t } = useTranslation("payroll/create");

  const ref = useRef(null);

  const handleSetTempArray = (item, newValue) =>
    setTempArr((prev) => {
      const { name } = item || {};
      const result = prev?.map((prevItem) => {
        const { name: prevItemName } = prevItem || {};
        const isSameName = name === prevItemName;
        const isName = name === "name";

        const isContainOnlyNumber = isName && containsOnlyDigits(newValue);
        const isRequired = isName;

        if (isRequired) {
          if (!newValue.length || isContainOnlyNumber) {
            setShowError(true);
          } else {
            setShowError(false);
          }
        }

        if (!isSameName) return prevItem;
        return {
          ...prevItem,
          value: newValue,
          invalid: isName ? (!!newValue ? false : true) : false,
        };
      });
      return result;
    });

  const handleOnChangeData = (newArrayData) =>
    setData((prev) => {
      const result = prev?.map((array, childIndex) => {
        const isChangedRow = index == childIndex;
        if (!isChangedRow) return array;

        const newArray = array?.map((prevItem) => {
          const { name: namePrev } = prevItem || {};
          const { isName, isRole, isDept } = useBooleanTable({
            name: namePrev,
          });
          const isCombination = isName || isRole || isDept;
          const getData = newArrayData?.filter(
            ({ name }) => name === namePrev
          )[0];
          // ====
          if (!isCombination) return prevItem;
          return getData;
        });

        return newArray;
      });
      return result;
    });

  const handleCancel = () => setTimeout(close, 20);
  const handleSubmit = () => {
    handleOnChangeData(tempArr);
    setTimeout(close, 20);
  };

  useClickOutside({
    ref,
    dependency: valueName,
    clickOutside: () => {
      if (!valueName) {
        handleCancel();
      } else {
        handleSubmit();
      }
    },
  });
  useEscapeKeypress({ ref, escapeTo: () => setTimeout(close, 20) });

  const isMoreThanFiveData = index >= 5;

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        backgroundColor: "white",
        top: !isMoreThanFiveData && 64,
        bottom: isMoreThanFiveData && 64,
        // left: isAmount ? -50 : 0,
        left: 0,
        minWidth: 416,
        // padding: showError ? "18px 8px 26px 8px" : "18px 8px",
        padding: "12px 8px",
        boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
        borderRadius: 4,
        zIndex: 99999,
        ...style,
      }}
    >
      {tempArr?.map((item, index) => {
        const isFirst = index === 0;
        const { name, value } = item || {};
        const { isName } = useBooleanTable({ name });
        const isContainOnlyNumber = isName && containsOnlyDigits(value);
        const isRequired = isName;

        const errorMsg = () => {
          if (isContainOnlyNumber)
            return t("Include alphabetic characters, not just numeric.");
          if (isRequired) return t("Please fill out this field");
          return "";
        };

        return (
          <div
            key={index}
            style={{ marginBottom: showError && isRequired ? 32 : 12 }}
          >
            <GothamMedium className="font12 mb-2">
              {t(titleCase(name))}
              {isRequired && <span style={{ color: jackColors.redE7 }}>*</span>}
            </GothamMedium>
            <MinimalistTextField
              name={name}
              label={""}
              error={showError}
              errorMsg={errorMsg()}
              required={isRequired && ""}
              value={value}
              setValue={(value) => handleSetTempArray(item, value)}
              style={{
                width: "100%",
                border: `1px solid ${jackColors.neutral500}`,
                borderRadius: 4,
              }}
              inputPropsStyle={{
                border: "none",
                padding: "6px 8px 5px 8px",
              }}
              autoFocus={isFirst && true}
            />
          </div>
        );
      })}
      <div className="d-flex w-100" style={{ gap: 16 }}>
        <ButtonJack
          children="Cancel"
          type="outline"
          style={{ width: "100%" }}
          onClick={handleCancel}
        />
        <ButtonJack
          children="Confirm"
          disabled={showError}
          style={{ width: "100%" }}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};
